<script setup lang="ts">
import { SettingsItemInfo } from "@/types";
import { useId } from "vue";

defineProps<{
	item: SettingsItemInfo;
	onChange: (item: SettingsItemInfo, event: Event) => void;
}>();

const id = useId();
</script>

<template>
	<div class="input-bar">
		<label :for="id">{{ item.label }}</label>
		<select
			v-if="item.options"
			:id="id"
			:value="item.default"
			@change="onChange(item, $event)"
		>
			<option
				v-for="option in item.options || []"
				:key="typeof option === 'string' ? option : option.value"
				:value="typeof option === 'string' ? option : option.value"
				:disabled="typeof option === 'object' && option.when === false"
			>
				{{ typeof option === "string" ? option : option.text }}
			</option>
		</select>
		<input
			v-else
			:id="id"
			class="input-box"
			:value="item.default"
			@input="onChange(item, $event)"
		/>
	</div>
</template>

<style scoped>
.input-bar {
	align-items: center;
	display: flex;
	margin: 5px 0;
}

.input-bar label {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 30%;
}

.input-bar input {
	flex: 1;
	font-size: 14px;
	margin: 0;
}

.input-bar select {
	flex: 1;
}
</style>
