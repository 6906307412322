<script setup lang="ts">
import { wrap } from "@/code-editor";
import { handleSaveClick } from "@/file";
import globals from "@/globals";
import { showSettings } from "@/settings";
import { Column } from "@/types";
import { closeSidebars, handleKeyboardClick } from "@/utils";
import { t } from "i18next";

const items = [
	{
		label: t("site"),
		onClick: (): void => {
			void closeSidebars();
			globals.state.currentColumn = Column.SiteList;
		},
	},
	{
		label: t("file"),
		onClick: (): void => {
			void closeSidebars();
			globals.state.currentColumn = Column.FileList;
		},
	},
	{
		label: t("code"),
		onClick: (): void => {
			void closeSidebars();
			if (globals.state.currentColumn === Column.CodeEditor) {
				showSettings();
				return;
			}
			globals.state.currentColumn = Column.CodeEditor;
		},
	},
	{
		label: t("wrap"),
		onClick: wrap,
	},
	{
		label: t("save"),
		onClick: handleSaveClick,
	},
];
</script>

<template>
	<nav>
		<span
			v-for="item in items"
			:key="item.label"
			role="button"
			tabindex="0"
			@click="() => item.onClick()"
			@keydown="handleKeyboardClick($event, () => item.onClick())"
		>
			{{ item.label }}
		</span>
	</nav>
</template>
