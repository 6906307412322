<script setup lang="ts">
import globals from "@/globals";
import { DialogInfo } from "@/types";
import { closeDialog } from "@/utils";
import { onMounted, useTemplateRef } from "vue";

const props = defineProps<{
	dialog: DialogInfo;
	isClosingDialog: boolean;
}>();

const defaultButtonRef = useTemplateRef<HTMLButtonElement>("defaultButton");

const handleCancelClick = (): void => {
	void globals.dialogCallbacks.cancel?.();
	void closeDialog(); // clears callbacks, so must be called last
};

const handleOkClick = (): void => {
	void globals.dialogCallbacks.ok?.();
	void closeDialog(); // clears callbacks, so must be called last
};

if (props.dialog.countdown) {
	const intervalId = window.setInterval(() => {
		if (!globals.state.dialog.countdown || !globals.state.dialog.text) {
			window.clearInterval(intervalId);
			return;
		}
		globals.state.dialog.countdown =
			globals.state.dialog.countdown > 0
				? globals.state.dialog.countdown - 1
				: 0;
	}, 1000);
}

onMounted(() => {
	defaultButtonRef.value?.focus();
});
</script>

<template>
	<focus-trap
		:active="!dialog.disableOk && !dialog.countdown"
		@deactivate="handleCancelClick"
	>
		<div
			class="overlay"
			:class="{
				'fade-in': !isClosingDialog,
				'fade-out': isClosingDialog,
			}"
		>
			<div
				class="popup msg-box"
				:class="{
					'fade-in': !isClosingDialog,
					'fade-out': isClosingDialog,
				}"
				role="dialog"
			>
				<h1 v-if="dialog.title">{{ dialog.title }}</h1>
				<div class="msg-box-txt">
					<span
						v-if="dialog.isHtml"
						v-html="dialog.text"
					></span>
					<template v-else>{{ dialog.text }}</template>
				</div>
				<div class="btn-bar">
					<button
						v-if="dialog.showCancel"
						class="cancel-btn"
						type="button"
						@click="handleCancelClick()"
					>
						{{ $t("cancel") }}
					</button>
					<button
						v-for="button in dialog.extraButtons"
						:key="button"
						type="button"
						@click="
							globals.dialogCallbacks[button]();
							closeDialog();
						"
					>
						{{ $t(button) }}
					</button>
					<button
						ref="defaultButton"
						class="default-btn"
						:disabled="dialog.disableOk || !!dialog.countdown"
						type="button"
						@click="handleOkClick()"
					>
						{{ $t("ok") }}
						<template v-if="dialog.countdown">
							{{ ` (${dialog.countdown})` }}
						</template>
					</button>
				</div>
			</div>
		</div>
	</focus-trap>
</template>

<style scoped>
.popup h1 {
	font-size: 20px;
	margin: 0 0 10px;
}
</style>
