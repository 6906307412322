<script setup lang="ts">
import globals from "@/globals";
import { PromptInfo } from "@/types";
import { sleep } from "@/utils";
import { onMounted, ref, useId, useTemplateRef } from "vue";

const props = defineProps<{
	prompt: PromptInfo;
}>();

const dataListId = props.prompt.dataList ? useId() : undefined;
const inputId = useId();
const inputRef = useTemplateRef<HTMLInputElement>("input");
const inputValue = ref<string>(props.prompt.defaultText || "");
const isClosing = ref<boolean>(false);

const closePromptPopup = async (): Promise<void> => {
	globals.promptCallback = null;
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.prompt = {};
	isClosing.value = false;
};

const handleSubmit = (event: Event): void => {
	event.preventDefault();
	void globals.promptCallback?.(inputValue.value);
	void closePromptPopup();
};

onMounted(async () => {
	await sleep(globals.ANIMATION_WAIT_TIME);
	inputRef.value?.focus();
});
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closePromptPopup"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
		>
			<form
				class="popup msg-box"
				:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
				role="dialog"
				@submit="handleSubmit"
			>
				<label
					class="msg-box-txt"
					:for="inputId"
				>
					{{ prompt.text }}
				</label>
				<input
					ref="input"
					class="input-box"
					:id="inputId"
					type="text"
					v-model="inputValue"
					:list="dataListId"
				/>
				<datalist
					v-if="prompt.dataList"
					:id="dataListId"
				>
					<option
						v-for="item in prompt.dataList"
						:key="item"
					>
						{{ item }}
					</option>
				</datalist>
				<div class="btn-bar">
					<button
						class="cancel-btn"
						type="button"
						@click="closePromptPopup"
					>
						{{ $t("cancel") }}
					</button>
					<button
						class="default-btn"
						type="submit"
					>
						{{ $t("ok") }}
					</button>
				</div>
			</form>
		</div>
	</focus-trap>
</template>
