<script lang="ts">
import { AppState, Column } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
	data: (): AppState => {
		return {
			changedFiles: [],
			Column: Column,
			contextMenuInfo: null,
			currentColumn: Column.CodeEditor,
			currentFile: "",
			currentFolder: "",
			currentSite: "",
			dialog: {},
			files: {},
			floatNotificationInfo: null,
			hasPremium: null,
			hasUnsaved: false,
			isAddSitePopupShown: false,
			isAiBarShown: false,
			isApiKeysPopupShown: false,
			isClosingDialog: false,
			isClosingSidebar: false,
			isClosingToast: false,
			isLoadingScreenShown: false,
			isLoginPanelShown: false,
			isMainMenuShown: false,
			isMonacoEnabled: false,
			isSettingsPopupShown: false,
			isSplashScreenShown: true,
			isSslPopupShown: false,
			login: {},
			oldDomain: "",
			pendingUploadFiles: [],
			premiumRemaining: 0,
			prompt: {},
			selectedFiles: [],
			sites: [],
			toast: {},
		};
	},
});
</script>

<template>
	<header-area :has-premium="hasPremium" />
	<site-list
		:current-site="currentSite"
		:sites="sites"
		:is-selected="currentColumn === Column.SiteList"
	/>
	<file-list
		:changed-files="changedFiles"
		:current-file="currentFile"
		:current-folder="currentFolder"
		:current-site="currentSite"
		:files="files"
		:is-selected="currentColumn === Column.FileList"
		:selected-files="selectedFiles"
	/>
	<ai-bar
		v-if="isAiBarShown"
		:current-file="currentFile"
		:files="files"
		:is-closing="isClosingSidebar"
	/>
	<upload-center
		v-if="pendingUploadFiles.length > 0"
		:current-folder="currentFolder"
		:is-closing="isClosingSidebar"
		:pending-upload-files="pendingUploadFiles"
	/>
	<navigation-bar />
	<corner-icons
		:current-file="currentFile"
		:files="files"
		:has-premium="hasPremium"
		:has-unsaved="hasUnsaved"
		:is-monaco-enabled="isMonacoEnabled"
	/>
	<login-panel v-if="isLoginPanelShown" />
	<splash-screen
		v-if="
			isSplashScreenShown || (isLoadingScreenShown && sites.length === 0)
		"
	/>
	<float-notification
		v-if="floatNotificationInfo"
		:float-notification-info="floatNotificationInfo"
	/>
	<main-menu
		v-if="isMainMenuShown"
		:files="files"
		:login="login"
		:premium-remaining="premiumRemaining"
	/>
	<add-site-popup
		v-if="isAddSitePopupShown"
		:is-closing="isClosingDialog"
		:login="login"
		:old-domain="oldDomain"
		:premium-remaining="premiumRemaining"
		:sites="sites"
	/>
	<settings-popup v-if="isSettingsPopupShown" />
	<api-keys-popup v-if="isApiKeysPopupShown" />
	<ssl-popup v-if="isSslPopupShown" />
	<prompt-popup
		v-if="prompt.text"
		:prompt="prompt"
	/>
	<dialog-popup
		v-if="dialog.text"
		:dialog="dialog"
		:is-closing-dialog="isClosingDialog"
	/>
	<context-menu
		v-if="contextMenuInfo"
		:context-menu-info="contextMenuInfo"
	/>
	<loading-screen v-if="isLoadingScreenShown && sites.length > 0" />
	<toast-popup
		v-if="toast.text"
		:is-closing-toast="isClosingToast"
		:toast="toast"
	/>
</template>
