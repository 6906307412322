<script setup lang="ts">
import {
	changeEditorFontSize,
	editorInstance,
	enableMonacoEditor,
} from "@/code-editor";
import { selectLocalFile } from "@/file";
import globals from "@/globals";
import {
	changeTheme,
	resetTheme,
	saveSettings,
	uploadSettings,
} from "@/settings";
import { SettingsItemInfo } from "@/types";
import { isPremium, showDialog, sleep } from "@/utils";
import { deleteWallpaper, saveWallpaper } from "@/wallpaper";
import i18next, { t } from "i18next";
import { ref } from "vue";

const isClosing = ref<boolean>(false);

const settingsItems: SettingsItemInfo[] = [
	{
		default: i18next.language,
		key: "language",
		label: t("language"),
		options: [
			{
				text: "简体中文",
				value: "zh-CN",
			},
			{
				text: "繁體中文",
				value: "zh-TW",
			},
			{
				text: "English",
				value: "en-US",
			},
		],
		reloadRequired: (newValue: string): boolean => {
			return newValue !== i18next.language;
		},
	},
	{
		default: globals.settings.theme || "dark",
		key: "theme",
		label: t("theme"),
		onChange: async (
			newValue: string,
			oldValue: string | undefined,
			target: HTMLInputElement | HTMLSelectElement | undefined,
		): Promise<void> => {
			if (newValue === "wallpaper") {
				if (isPremium()) {
					await showDialog(t("selectImage"));
					selectLocalFile(
						{
							accept: "image/*",
							multiple: false,
						},
						(files) => {
							void saveWallpaper(files[0]);
						},
					);
				} else if (oldValue && target instanceof HTMLSelectElement) {
					resetTheme(oldValue, target);
				}
			} else {
				void deleteWallpaper();
				document.documentElement.classList.remove("has-bg-img");
			}
			changeTheme(
				newValue === "light" ||
					(newValue === "system" &&
						matchMedia("(prefers-color-scheme: light)").matches),
			);
		},
		options: [
			{
				text: t("systemDefault"),
				value: "system",
			},
			{
				text: t("light"),
				value: "light",
			},
			{
				text: t("dark"),
				value: "dark",
			},
			{
				text: t("customWallpaper"),
				value: "wallpaper",
			},
		],
	},
	{
		confirm: {
			text: t("thisCodeEditorNotOptimizedForMobile"),
			when: (newValue: string): boolean => {
				return (
					globals.isMobile && !editorInstance && newValue === "vscode"
				);
			},
		},
		default: editorInstance ? "vscode" : "native",
		key: "codeEditor",
		label: t("codeEditor"),
		onChange: (newValue: string): void => {
			if (!editorInstance && newValue === "vscode") {
				void enableMonacoEditor();
			}
		},
		options: [
			{
				text: t("nativeTextBox"),
				value: "native",
			},
			{
				text: "Monaco Editor (VSCode)",
				value: "vscode",
				when: globals.isMonacoSupported,
			},
		],
		reloadRequired: (newValue: string): boolean => {
			return !!editorInstance && newValue === "native";
		},
	},
	{
		default: globals.settings.fontSize,
		key: "fontSize",
		label: t("fontSize"),
		onChange: (newValue: string): void => {
			changeEditorFontSize(false); // no need to save twice
			void uploadSettings("fontSize", newValue);
		},
		options: ((): string[] => {
			const array = [];
			for (let i = 12; i <= 72; i += 2) {
				array.push(i.toString());
			}
			return array;
		})(),
	},
	{
		confirm: {
			text: t("confirmEnableJsProcessor"),
			when: (newValue: string): boolean => {
				return !!newValue;
			},
		},
		default: globals.settings.jsProcessor || "none",
		key: "jsProcessor",
		label: t("jsProcessor"),
		onChange: (newValue: string): void => {
			void uploadSettings("jsProcessor", newValue || "false");
		},
		options: [
			{
				text: t("none"),
				value: "none",
			},
			{
				text: "JavaScript Obfuscator",
				value: "obfuscator",
			},
		],
	},
];

async function closeSettingsPopup(): Promise<void> {
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isSettingsPopupShown = false;
	isClosing.value = false;
}

async function handleChange(
	item: SettingsItemInfo,
	event: Event,
): Promise<void> {
	const { target } = event;
	if (
		!(target instanceof HTMLInputElement) &&
		!(target instanceof HTMLSelectElement)
	) {
		throw new Error("Invalid event target.");
	}
	const newValue: string = target.value === "none" ? "" : target.value;
	if (item.confirm && item.confirm.when?.(newValue)) {
		await showDialog(
			item.confirm.text,
			{
				showCancel: true,
			},
			{
				cancel: () => {
					target.value = item.default.toString();
				},
			},
		);
	}
	const oldValue = globals.settings[item.key] || item.default;
	globals.settings[item.key] = newValue;
	saveSettings();
	void item.onChange?.(newValue, oldValue.toString(), target);
	if (item.reloadRequired && item.reloadRequired(newValue)) {
		await showDialog(t("refreshToApplySettings"), {
			showCancel: true,
		});
		window.location.reload();
	}
}
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closeSettingsPopup"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
		>
			<div
				class="popup"
				:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
				role="dialog"
			>
				<h1>{{ $t("settings") }}</h1>
				<settings-item
					v-for="item in settingsItems"
					:key="item.key"
					:item="item"
					@change="handleChange"
				/>
				<div class="btn-bar">
					<button
						class="cancel-btn default-btn"
						type="button"
						@click="closeSettingsPopup"
					>
						{{ $t("ok") }}
					</button>
				</div>
			</div>
		</div>
	</focus-trap>
</template>
