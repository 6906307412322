import * as api from "@/api";
import globals from "@/globals";
import { getDomain } from "@/site";
import { closeDialog, showDialog, sleep } from "@/utils";
import { t } from "i18next";

class SSL {
	private readonly domain: string;

	public constructor() {
		this.domain = getDomain(globals.state.currentSite);
	}

	public applyCert(orderUrl: string, hasRetried = false): void {
		let seconds = hasRetried ? 540 : 60;
		void showDialog(
			t("certificateCountdownTip", {
				seconds: seconds,
			}),
			{
				disableOk: true,
			},
		);
		const countdownId = setInterval(() => {
			void (async (): Promise<void> => {
				if (seconds <= 0) {
					clearInterval(countdownId);
					return;
				}
				seconds--;
				globals.state.dialog.text = t("certificateCountdownTip", {
					seconds: seconds,
				});
				if (seconds > 0) {
					return;
				}
				clearInterval(countdownId);
				void closeDialog();
				globals.state.isLoadingScreenShown = true;
				try {
					const data = await api.applyForSsl(this.domain, orderUrl);
					if (data?.error) {
						if (!hasRetried && data.error !== "noEnoughSpace") {
							this.applyCert(orderUrl, true);
						} else {
							void showDialog(data.message || data.error);
						}
					} else {
						void showDialog(t("certificateIssuedSuccessfully"));
					}
				} catch (error) {
					void api.handleApiError(error);
				} finally {
					globals.state.isLoadingScreenShown = false;
				}
			})();
		}, 1000);
	}

	public async createSsl(): Promise<void> {
		globals.state.isLoadingScreenShown = true;
		try {
			const data = await api.createSsl(this.domain);
			if (!data) {
				return;
			}
			if (data.error) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(data.message || data.error);
			} else if (data.orderUrl) {
				this.applyCert(data.orderUrl);
			}
		} catch (error) {
			void api.handleApiError(error);
		} finally {
			globals.state.isLoadingScreenShown = false;
		}
	}

	public async deleteSsl(): Promise<void> {
		await showDialog(t("confirmDeleteExistingSslCertificate"), {
			showCancel: true,
		});
		globals.state.isLoadingScreenShown = true;
		try {
			const data = await api.deleteSsl(this.domain);
			await sleep(globals.ANIMATION_WAIT_TIME);
			if (data?.error) {
				void showDialog(data.alert || data.error);
				return;
			}
			void showDialog(t("deletedSuccessfully"));
		} catch (error) {
			void api.handleApiError(error);
		} finally {
			globals.state.isLoadingScreenShown = false;
		}
	}

	public async uploadSsl(cert: File, key: File): Promise<void> {
		globals.state.isLoadingScreenShown = true;
		try {
			const data = await api.uploadSsl(cert, key, this.domain, () => {
				void showDialog(t("certificateInvalid"));
			});
			if (data?.error) {
				void showDialog(data.alert || data.error);
				return;
			}
			void showDialog(t("uploadedSuccessfully"));
		} catch (error) {
			void api.handleApiError(error);
		} finally {
			globals.state.isLoadingScreenShown = false;
		}
	}
}

export default SSL;
