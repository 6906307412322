import * as api from "@/api";
import { monaco } from "@/code-editor";
import globals from "@/globals";
import { deleteWallpaper } from "@/wallpaper";

export function changeTheme(newValue: boolean): void {
	globals.isLight = newValue;
	if (globals.isLight) {
		document.documentElement.classList.add("light");
		monaco.editor.setTheme("vs");
		globals.themeColor.content = "#ffffff";
	} else {
		document.documentElement.classList.remove("light");
		monaco.editor.setTheme("vs-dark");
		globals.themeColor.content = "#000000";
	}
}

export function saveSettings(): void {
	localStorage.setItem("Settings", JSON.stringify(globals.settings));
}

export function showSettings(): void {
	if (globals.settings.theme !== "wallpaper") {
		void deleteWallpaper();
	}
	globals.state.isSettingsPopupShown = true;
}

export function resetTheme(oldValue: string, select: HTMLSelectElement): void {
	globals.settings.theme = oldValue;
	select.value = oldValue;
	saveSettings();
}

export async function uploadSettings(
	key: string,
	value: string,
): Promise<void> {
	try {
		if (!globals.privilege) {
			return;
		}
		await api.setSettings(key, value);
	} catch (error) {
		void api.handleApiError(error);
	}
}
