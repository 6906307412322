<script setup lang="ts">
import * as api from "@/api";
import { setValue } from "@/code-editor";
import {
	getUrl,
	readTextFile,
	resetFileList,
	selectLocalFile,
	visitPage,
} from "@/file";
import globals from "@/globals";
import {
	exportAsZip,
	getDomain,
	getFullDomain,
	importSite,
	newSite,
	openSite,
	rmBackup,
} from "@/site";
import { SiteContent } from "@/types";
import {
	copyText,
	encodeData,
	handleKeyboardClick,
	isPremium,
	parseJson,
	showContextMenu,
	showDialog,
	showPrompt,
	sleep,
} from "@/utils";
import { t } from "i18next";
import { computed } from "vue";

const props = defineProps<{
	currentSite: string;
	isSelected: boolean;
	sites: string[];
}>();

const sorted = computed(() => {
	const array = [];
	for (const site of props.sites) {
		array.push(getFullDomain(decodeURIComponent(site)));
	}
	return array.sort();
});

function clicked(name: string, event?: MouseEvent): void {
	if (globals.isMobile) {
		rightClicked(name, event);
	} else {
		void openSite(name);
	}
}

async function deleteSite(fullDomain: string): Promise<void> {
	const domain = getDomain(fullDomain);
	const encodedDomain = encodeURIComponent(domain);
	const enteredName = await showPrompt(
		t("enterToConfirmDeletion", {
			name: domain,
		}),
	);
	if (!enteredName.toLowerCase().includes(domain)) {
		return;
	}
	globals.state.isLoadingScreenShown = true;
	try {
		const data = await api.deleteSite(encodedDomain);
		if (fullDomain === props.currentSite) {
			document.getElementById("main")?.classList.remove("img");
			setValue();
			globals.state.currentSite = "";
			resetFileList();
			rmBackup();
		}
		if (data?.alert) {
			void showDialog(data.alert);
			return;
		}
		const index = props.sites.findIndex((key) => {
			return key === encodedDomain;
		});
		if (index !== -1) {
			globals.state.sites.splice(index, 1);
		}
		void showDialog(fullDomain + t("colon") + t("deletedSuccessfully"));
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

function rightClicked(fullDomain: string, event?: MouseEvent): void {
	showContextMenu(event, [
		{
			content: {
				icon: "folder-open",
				text: t("open"),
			},
			onClick: (): void => {
				void openSite(fullDomain);
			},
			when: globals.isMobile,
		},
		{
			content: {
				icon: "globe",
				text: t("visit"),
			},
			onClick: (): void => {
				visitPage("", fullDomain);
			},
			when: true,
		},
		{
			content: {
				icon: "link",
				text: t("copyLink"),
			},
			onClick: async (): Promise<void> => {
				await copyText(getUrl(false, "", fullDomain));
			},
			when: true,
		},
		{
			content: {
				icon: "pen-to-square",
				text: t("rename"),
			},
			onClick: async (): Promise<void> => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				newSite(getDomain(fullDomain));
			},
			when: true,
		},
		{
			content: {
				icon: "file-export",
				text: t("export"),
			},
			onClick: async (): Promise<void> => {
				await sleep(globals.ANIMATION_WAIT_TIME * 3);
				showContextMenu(event, [
					{
						content: {
							icon: "file-code",
							text: "JSON",
						},
						onClick: (): void => {
							window.open(
								globals.BACKEND_HOST +
									"get?" +
									encodeData({
										action: "export",
										domain: encodeURIComponent(
											getDomain(fullDomain),
										),
									}),
							);
						},
						when: true,
					},
					{
						content: {
							icon: "file-zipper",
							text: "ZIP",
						},
						onClick: async (): Promise<void> => {
							if (!isPremium()) {
								return;
							}
							if (props.currentSite !== fullDomain) {
								await openSite(fullDomain);
							}
							await sleep(globals.ANIMATION_WAIT_TIME);
							void exportAsZip(fullDomain);
						},
						when: true,
					},
				]);
			},
			when: true,
		},
		{
			content: {
				icon: "file-import",
				text: t("import"),
			},
			onClick: (): void => {
				selectLocalFile(
					{
						accept: "",
						multiple: false,
					},
					async (files: FileList) => {
						const file = files[0];
						if (file.size > 2097152) {
							void showDialog(
								t("fileCannotLargerThan", {
									size: "2 MB",
								}),
							);
						} else {
							const data = await readTextFile(file);
							void importSite(
								parseJson<SiteContent>(data),
								fullDomain,
							);
						}
					},
				);
			},
			when: true,
		},
		{
			content: {
				icon: "chart-simple",
				text: t("analytics"),
			},
			onClick: async (): Promise<void> => {
				if (!isPremium()) {
					return;
				}
				globals.state.isLoadingScreenShown = true;
				try {
					const data = await api.getAnalytics(getDomain(fullDomain));
					void showDialog(data, {
						isHtml: true,
					});
				} catch (error) {
					void api.handleApiError(error);
				} finally {
					globals.state.isLoadingScreenShown = false;
				}
			},
			when: true,
		},
		{
			content: {
				icon: "trash-can",
				text: t("delete"),
			},
			onClick: async (): Promise<void> => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void deleteSite(fullDomain);
			},
			when: true,
		},
	]);
}
</script>

<template>
	<div
		:aria-hidden="!isSelected"
		class="list site-list"
		:class="{ selected: isSelected }"
	>
		<div
			aria-haspopup="dialog"
			class="head-btn"
			role="button"
			tabindex="0"
			@click="() => newSite()"
			@keydown="handleKeyboardClick($event, () => newSite())"
		>
			<font-awesome-icon icon="plus" />
			<span>{{ $t("newSite") }}</span>
		</div>
		<ul>
			<li
				v-for="name in sorted"
				:aria-selected="currentSite === name"
				:key="name"
				role="option"
				tabindex="0"
				@click="($event) => clicked(name, $event)"
				@contextmenu.prevent="($event) => rightClicked(name, $event)"
				@keydown="handleKeyboardClick($event, () => clicked(name))"
			>
				{{ name }}
			</li>
		</ul>
	</div>
</template>

<style scoped>
.site-list {
	left: 0;
}
</style>
