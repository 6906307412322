import globals from "@/globals";
import { showDialog } from "@/utils";
import { t } from "i18next";

let database: IDBDatabase | null;

export function connectDatabase(): Promise<IDBDatabase> {
	return new Promise<IDBDatabase>((resolve, reject) => {
		if (database) {
			resolve(database);
			return;
		}
		const dbRequest = indexedDB.open(globals.APP_NAME, 1);
		dbRequest.onsuccess = (): void => {
			database = dbRequest.result;
			resolve(database);
		};
		dbRequest.onerror = reject;
		dbRequest.onupgradeneeded = (): void => {
			database = null;
			const store = dbRequest.result.createObjectStore("wallpaper");
			store.transaction.oncomplete = (): void => {
				database = store.transaction.db;
			};
		};
	});
}

export async function deleteWallpaper(): Promise<void> {
	const database = await connectDatabase();
	const dbName = "wallpaper";
	database.transaction(dbName, "readwrite").objectStore(dbName).clear();
}

export async function saveWallpaper(file: File): Promise<void> {
	if (!file) {
		return;
	}
	const dbName = "wallpaper";
	try {
		const database = await connectDatabase();
		database
			.transaction(dbName, "readwrite")
			.objectStore(dbName)
			.put(file, "custom");
		setWallpaper(file);
	} catch (error) {
		console.error(error);
		void showDialog(t("unableSetWallpaperInIncognito"));
	}
}

export function setWallpaper(file: File): void {
	if (!file) {
		return;
	}
	const wallpaperUrl = URL.createObjectURL(file);
	document.documentElement.classList.add("has-bg-img");
	const bgImg = document.getElementById("bg-img") as HTMLDivElement;
	bgImg.style.backgroundImage = `url(${wallpaperUrl})`;
}
