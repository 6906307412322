/* eslint-disable @typescript-eslint/explicit-function-return-type */

export async function importBrowserImageCompression() {
	return (await import("browser-image-compression")).default;
}

export async function importPizZip() {
	return (await import("pizzip")).default;
}

export async function importXterm() {
	await import("@xterm/xterm/css/xterm.css");
	return (await import("@xterm/xterm")).Terminal;
}

export function loadDependencies(): void {
	window.setTimeout(() => {
		void importBrowserImageCompression();
		void importPizZip();
	}, 1000);
}
