<script setup lang="ts">
import globals from "@/globals";
import { ContextMenuInfo } from "@/types";
import { handleKeyboardClick, sleep } from "@/utils";
import { onMounted, ref, useTemplateRef } from "vue";

const props = defineProps<{
	contextMenuInfo: ContextMenuInfo | null;
}>();

const contextMenuRef = useTemplateRef<HTMLDivElement>("contextMenu");
const isClosing = ref<boolean>(false);
const x = ref<number>(0);
const y = ref<number>(0);

async function closeMenu(): Promise<void> {
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.contextMenuInfo = null;
	isClosing.value = false;
}

function handleClick(item: string, event?: MouseEvent): void {
	void globals.contextMenuCallbacks[item]?.(event);
	void closeMenu();
}

onMounted(() => {
	const offsetHeight = contextMenuRef.value?.offsetHeight || 0;
	const offsetWidth = contextMenuRef.value?.offsetWidth || 0;
	if (
		props.contextMenuInfo &&
		props.contextMenuInfo.x &&
		props.contextMenuInfo.y
	) {
		if (props.contextMenuInfo.x - offsetWidth / 2 > 0) {
			if (
				props.contextMenuInfo.x - offsetWidth / 2 >
				innerWidth - offsetWidth
			) {
				x.value = innerWidth - offsetWidth;
			} else {
				x.value = props.contextMenuInfo.x - offsetWidth / 2;
			}
		}
		if (props.contextMenuInfo.y + 10 > innerHeight - offsetHeight) {
			y.value = props.contextMenuInfo.y - offsetHeight - 10;
		} else {
			y.value = props.contextMenuInfo.y + 10;
		}
	} else {
		x.value = innerWidth / 2 - offsetWidth / 2;
		y.value = innerHeight / 2 - offsetHeight / 2;
	}
});
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closeMenu"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
			role="button"
			tabindex="0"
			:aria-label="$t('closeMenu')"
			@click="closeMenu"
			@keydown="handleKeyboardClick($event, closeMenu)"
		>
			<div
				ref="contextMenu"
				class="popup-menu"
				:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
				:style="
					contextMenuInfo && contextMenuInfo.x && contextMenuInfo.y
						? { left: x + 'px', top: y + 'px' }
						: {}
				"
			>
				<div
					v-for="(item, index) in contextMenuInfo?.items"
					:key="item.text"
					role="button"
					tabindex="0"
					@click="handleClick(item.text, $event)"
					@keydown="
						handleKeyboardClick($event, () =>
							handleClick(item.text),
						)
					"
				>
					<font-awesome-icon
						fixedWidth
						:icon="item.icon"
					/>
					<span>{{ $t(item.text) }}</span>
				</div>
			</div>
		</div>
	</focus-trap>
</template>

<style scoped>
.popup-menu {
	background-color: var(--bg-color);
	border: 1px solid var(--fg-alpha-2);
	border-radius: 10px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
	cursor: default;
	font-size: 12px;
	max-width: 100%;
	overflow: auto;
	padding: 5px;
	position: fixed;
	white-space: nowrap;
}

.popup-menu div {
	align-items: center;
	border-radius: 5px;
	display: inline-flex;
	flex-direction: column;
	line-height: 1;
	padding: 10px;
	transition: 0.25s;
}

.popup-menu div:hover {
	background-color: var(--theme-color);
	color: white;
}

.popup-menu div:active {
	color: rgba(255, 255, 255, 0.5);
}

.popup-menu div:not(:first-of-type) {
	margin-left: 5px;
}

.popup-menu div svg {
	font-size: 16px;
	margin-bottom: 8px;
}
</style>
