<script setup lang="ts">
import { importBrowserImageCompression } from "@/dependencies";
import { fileExists, openFile, readTextFile, save } from "@/file";
import globals from "@/globals";
import { importSite } from "@/site";
import { AppState, FileUploadStatus, SiteContent } from "@/types";
import { closeUploadCenter, getPremium, parseJson, showDialog } from "@/utils";
import { t } from "i18next";
import { computed, ref } from "vue";

const props = defineProps<{
	currentFolder: string;
	isClosing: boolean;
	pendingUploadFiles: AppState["pendingUploadFiles"];
}>();

const allFilesStatus = ref<FileUploadStatus>(FileUploadStatus.NOT_UPLOADED);
const fixedCurrentFolder = ref<string | null>(null);
const showUploadButton = ref<boolean>(true);

const tip = computed(() => {
	const filesInfo = props.pendingUploadFiles;
	for (const fileInfo of filesInfo) {
		if (fileInfo.path.toLowerCase().includes("jquery")) {
			return t("noNeedToUploadJquery");
		}
		if (fileInfo.type.startsWith("text/")) {
			return t("recommendWritingCodeOnWebPage");
		}
	}
	return "";
});

function confirmMultiplePhpFiles(phpCount: number): Promise<void> {
	return showDialog(
		t("multiplePhpFilesToBeUploaded", {
			count: phpCount,
		}) + t("confirmContinueUpload"),
		{
			countdown: 5,
			showCancel: true,
		},
	);
}

async function handleUploadClick(): Promise<void> {
	const filesCount = props.pendingUploadFiles.length;
	if (!globals.privilege && filesCount > 1) {
		await showDialog(
			t("premiumRequiredForUploadingMultipleFiles") +
				t("refreshIfJustPaid"),
			{
				showCancel: true,
			},
		);
		void getPremium();
		return;
	}
	let phpCount = 0;
	let size = 0;
	for (const key in globals.pendingUploadFiles) {
		const file = globals.pendingUploadFiles[key];
		if (file.name.includes("wp-")) {
			void showDialog(t("wordpressUnsupported"));
			return;
		}
		if (file.name.endsWith(".php")) {
			phpCount++;
		}
		if (globals.accept.test(file.name) || file.type.includes("text/")) {
			size += file.size;
		}
	}
	if (phpCount > 1) {
		await confirmMultiplePhpFiles(phpCount);
	}
	if (globals.privilege && size > 2097152) {
		if (filesCount <= 1) {
			void showDialog(t("noEnoughSpace"));
		} else {
			await showDialog(
				t("textFilesToBeUploadedTooLarge") + t("confirmContinueUpload"),
				{
					showCancel: true,
				},
			);
			void uploadFile(true);
		}
	} else {
		void uploadFile(false);
	}
}

async function uploadFile(asBinary: boolean): Promise<void> {
	const currentFolder = props.currentFolder;
	const filesInfo = props.pendingUploadFiles;
	allFilesStatus.value = FileUploadStatus.UPLOADING;
	fixedCurrentFolder.value = currentFolder;
	showUploadButton.value = false;
	for (const fileInfo of filesInfo) {
		let file = globals.pendingUploadFiles[fileInfo.path];
		if (!file) {
			fileInfo.skipReason = t("fileNotFound");
			fileInfo.status = FileUploadStatus.FAILED;
			continue;
		}
		if (file.size > 1048576 && file.type.startsWith("image/")) {
			const imageCompression = await importBrowserImageCompression();
			const compressedBlob = await imageCompression(file, {
				libURL: "https://registry.npmmirror.com/browser-image-compression/2.0.2/files/dist/browser-image-compression.js",
				maxSizeMB: 1,
			});
			file = new File([compressedBlob], file.name, {
				type: file.type,
			});
		}
		if (file.size > 5242880) {
			void showDialog(
				t("fileCannotLargerThanOtherwiseAffectSpeed", {
					size: "5 MB",
				}) +
					((): string => {
						if (file.type.startsWith("image/")) {
							return t("largeImageSolution");
						} else if (file.type.startsWith("video/")) {
							return t("largeVideoSolution");
						} else {
							return t("largeFileSolution");
						}
					})(),
			);
			fileInfo.skipReason = t("fileTooLarge");
			fileInfo.status = FileUploadStatus.FAILED;
			allFilesStatus.value = FileUploadStatus.FAILED;
			return;
		}
		const path = currentFolder + fileInfo.path;
		const pathLowered = path.toLowerCase();
		if (pathLowered === "settings.rth") {
			fileInfo.skipReason = t("thisIsInternalFile");
			fileInfo.status = FileUploadStatus.FAILED;
			continue;
		}
		if (pathLowered.endsWith(".scss")) {
			fileInfo.skipReason = t("fileNotNeededForRunningSite");
			fileInfo.status = FileUploadStatus.FAILED;
			continue;
		}
		const isAccepted = globals.accept.test(fileInfo.path);
		const isBinary =
			(asBinary &&
				fileInfo.path !== globals.state.files["settings.rth"]?.home) ||
			(!isAccepted &&
				!file.type.includes("text/") &&
				!pathLowered.endsWith(".php")) ||
			(globals.privilege && pathLowered.includes(".min."));
		if (!globals.privilege && (!isAccepted || isBinary)) {
			fileInfo.skipReason = t("premiumRequired");
			fileInfo.status = FileUploadStatus.FAILED;
			allFilesStatus.value = FileUploadStatus.FAILED;
			await showDialog(
				t("premiumRequiredForUploadingThisFileType") +
					t("refreshIfJustPaid"),
				{
					showCancel: true,
				},
			);
			void getPremium();
			return;
		}
		if (isBinary) {
			if (fileExists(path)) {
				fileInfo.skipReason = t("fileAlreadyExists");
				fileInfo.status = FileUploadStatus.FAILED;
				continue;
			}
			fileInfo.status = FileUploadStatus.UPLOADING;
			const hasSaved = await save({
				content: file,
				hideLoading: true,
				key: path,
			});
			fileInfo.status = hasSaved
				? FileUploadStatus.UPLOADED
				: FileUploadStatus.FAILED;
		} else {
			const content = await readTextFile(file);
			if (file.size <= 2097152 && pathLowered.endsWith(".json")) {
				const json = parseJson<SiteContent>(content);
				if (json["settings.rth"]) {
					if (filesInfo.length === 1) {
						void importSite(json);
					} else {
						fileInfo.skipReason = t("thisIsInternalFile");
						fileInfo.status = FileUploadStatus.FAILED;
						continue;
					}
					return;
				}
			}
			if (globals.state.files[path] === content) {
				fileInfo.skipReason = t("fileContentSame");
				fileInfo.status = FileUploadStatus.FAILED;
				continue;
			}
			globals.state.files[path] = content;
			fileInfo.status = FileUploadStatus.UPLOADING;
			const hasSaved = await save({
				hideLoading: true,
				key: path,
			});
			fileInfo.status = hasSaved
				? FileUploadStatus.UPLOADED
				: FileUploadStatus.FAILED;
		}
	}
	allFilesStatus.value = FileUploadStatus.UPLOADED;
	const fileToOpen = filesInfo.find((fileInfo) => {
		return (
			fileInfo.status === FileUploadStatus.UPLOADED &&
			fileInfo.type.startsWith("text/")
		);
	});
	if (fileToOpen) {
		void openFile(fileToOpen.path);
	}
}
</script>

<template>
	<sidebar-shell
		:close-sidebar="closeUploadCenter"
		:is-closing="isClosing"
	>
		<template v-slot:title>
			<font-awesome-icon
				class="icon"
				icon="upload"
				fixed-width
			/>
			<span class="text">{{ $t("uploadCenter") }}</span>
		</template>
		<template v-slot:content>
			<div
				class="with-icon"
				v-if="allFilesStatus === FileUploadStatus.UPLOADING"
			>
				<font-awesome-icon
					class="icon"
					icon="spinner"
					spin-pulse
					fixed-width
				/>
				<span class="text">{{ $t("uploading") }}</span>
			</div>
			<div
				class="with-icon"
				v-if="allFilesStatus === FileUploadStatus.UPLOADED"
			>
				<font-awesome-icon
					class="icon"
					icon="check"
					fixed-width
				/>
				<span class="text">{{ $t("allFilesUploaded") }}</span>
			</div>
			<div
				class="with-icon"
				v-if="tip"
			>
				<font-awesome-icon
					class="icon"
					icon="triangle-exclamation"
					fixed-width
				/>
				<span class="text">{{ tip }}</span>
			</div>
			<button
				v-if="showUploadButton"
				type="submit"
				@click="handleUploadClick"
			>
				{{ $t("startUpload") }}
			</button>
			<ul>
				<li
					v-for="fileInfo in pendingUploadFiles"
					:key="fileInfo.path"
				>
					<div class="with-icon">
						<font-awesome-icon
							class="icon"
							v-if="
								fileInfo.status ===
								FileUploadStatus.NOT_UPLOADED
							"
							icon="pause"
							fixed-width
						/>
						<font-awesome-icon
							class="icon"
							v-if="
								fileInfo.status === FileUploadStatus.UPLOADING
							"
							icon="spinner"
							spin-pulse
							fixed-width
						/>
						<font-awesome-icon
							class="icon"
							v-if="fileInfo.status === FileUploadStatus.UPLOADED"
							icon="check"
							fixed-width
						/>
						<font-awesome-icon
							class="icon"
							v-if="fileInfo.status === FileUploadStatus.FAILED"
							icon="times"
							fixed-width
						/>
						<div class="text">
							{{
								(fixedCurrentFolder ?? props.currentFolder) +
								fileInfo.path
							}}
							<div
								v-if="fileInfo.skipReason"
								class="description"
							>
								{{ fileInfo.skipReason }}
							</div>
						</div>
					</div>
				</li>
			</ul>
		</template>
	</sidebar-shell>
</template>

<style scoped>
li {
	--radius: 5px;
	align-items: center;
	background-color: var(--fg-alpha-1);
	display: flex;
	padding: 10px;
	overflow: hidden;
	word-break: break-all;
}

li:first-of-type {
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
}

li:last-of-type {
	border-bottom-left-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
}

li:nth-child(even) {
	background-color: var(--fg-alpha-05);
}

ul {
	padding-left: 0;
	padding-right: 0;
}
</style>
