<script setup lang="ts">
import globals from "@/globals";
import { FloatNotificationInfo } from "@/types";
import { handleKeyboardClick, sleep } from "@/utils";
import { ref } from "vue";

defineProps<{
	floatNotificationInfo: FloatNotificationInfo;
}>();

const isClosing = ref<boolean>(false);

async function closeNotification(): Promise<void> {
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.floatNotificationInfo = null;
	isClosing.value = false;
}

function handleClick(): void {
	globals.floatNotificationCallbacks.onClick?.();
	void closeNotification();
}

function handleClose(): void {
	globals.floatNotificationCallbacks.onClose?.();
	void closeNotification();
}
</script>

<template>
	<div
		class="notification-float"
		:class="{ 'slide-in': !isClosing, 'slide-out': isClosing }"
		role="button"
		tabindex="0"
		@click="handleClick"
		@keydown="handleKeyboardClick($event, handleClick)"
	>
		<font-awesome-icon icon="circle-exclamation" />
		<div
			class="content"
			:class="{ fold: floatNotificationInfo.isFolded }"
		>
			<h1>
				{{ floatNotificationInfo.title }}
			</h1>
			<div class="description">
				{{ floatNotificationInfo.text }}
			</div>
		</div>
		<button
			type="button"
			@click.stop="handleClose"
		>
			{{ $t("close") }}
		</button>
	</div>
</template>

<style scoped>
.notification-float {
	align-items: center;
	background-color: var(--bg-color);
	border: 1px solid var(--fg-alpha-1);
	border-radius: 10px;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	display: flex;
	overflow: hidden;
	padding: 15px 0 15px 15px;
	position: fixed;
	right: 20px;
	top: 70px;
	width: 350px;
}

.notification-float button {
	border: none;
	border-left: 1px solid var(--fg-alpha-1);
	border-radius: 0;
	color: var(--fg-alpha-8);
	font-size: 13px;
	height: 100%;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	text-transform: uppercase;
	transition: 0.25s;
	width: 70px;
}

.notification-float h1 {
	font-size: 16px;
	margin: 0;
}

.notification-float svg {
	color: var(--text-theme-color);
	font-size: 26px;
}

.notification-float .content {
	margin-left: 15px;
	width: 210px;
}

.notification-float .content.fold * {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.notification-float .description {
	color: var(--fg-alpha-8);
	font-size: 13px;
	margin-top: 3px;
}
</style>
