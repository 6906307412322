<template>
	<div class="splash-screen">
		<div>
			<div class="progress-bar"></div>
			<div class="app-icon"></div>
			<div class="app-name">{{ $t("rthWebHosting") }}</div>
		</div>
	</div>
</template>

<style scoped>
.app-icon {
	--size: 125px;
	border-radius: 10px;
	height: var(--size);
	margin: 0 auto 25px;
	transition:
		height 0.25s,
		width 0.25s;
	width: var(--size);
}

.app-name {
	font-size: 1.75em;
	line-height: 1.5;
	text-align: center;
	transition: font-size 0.25s;
}

.progress-bar {
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: progress-loop;
	animation-timing-function: linear;
	background-color: var(--theme-color);
	height: 2px;
	left: 0;
	position: fixed;
	top: 50px;
}

.splash-screen {
	align-items: center;
	background-color: var(--bg-color);
	display: flex;
	height: calc(100% - 50px);
	left: 0;
	position: fixed;
	top: 50px;
	transition: all 0.25s;
	width: 100%;
	z-index: 1;
}

.splash-screen > div {
	margin-bottom: 50px;
	width: 100%;
}

@keyframes progress-loop {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}

@media screen and (max-width: 900px) {
	.app-icon {
		--size: 100px;
	}

	.app-name {
		font-size: 1.5em;
	}
}
</style>
