/* eslint-disable @typescript-eslint/no-unsafe-argument */

import * as api from "@/api";
import {
	changeEditorFontSize,
	codeChanged,
	editorInstance,
	enableMonacoEditor,
	reloadMonacoEditor,
	setValue,
	wrap,
} from "@/code-editor";
import AddSitePopup from "@/components/AddSitePopup.vue";
import AiBar from "@/components/AiBar.vue";
import AiIcon from "@/components/AiIcon.vue";
import ApiKeysPopup from "@/components/ApiKeysPopup.vue";
import App from "@/components/App.vue";
import ChatMessage from "@/components/ChatMessage.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import CornerIcons from "@/components/CornerIcons.vue";
import DialogPopup from "@/components/DialogPopup.vue";
import FileList from "@/components/FileList.vue";
import FloatNotification from "@/components/FloatNotification.vue";
import HeaderArea from "@/components/HeaderArea.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import LoginPanel from "@/components/LoginPanel.vue";
import MainMenu from "@/components/MainMenu.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import NewApiKeyPopup from "@/components/NewApiKeyPopup.vue";
import PromptPopup from "@/components/PromptPopup.vue";
import SettingsItem from "@/components/SettingsItem.vue";
import SettingsPopup from "@/components/SettingsPopup.vue";
import SidebarShell from "@/components/SidebarShell.vue";
import SiteList from "@/components/SiteList.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import SslPopup from "@/components/SslPopup.vue";
import ToastPopup from "@/components/ToastPopup.vue";
import UploadCenter from "@/components/UploadCenter.vue";
import { loadDependencies } from "@/dependencies";
import { handleDrop } from "@/drop";
import {
	deleteFile,
	handleSaveClick,
	isFileOpened,
	renameFilePrompt,
} from "@/file";
import globals from "@/globals";
import "@/style.css";
import translationEnUs from "@/translations/en-us.json";
import translationZhCn from "@/translations/zh-cn.json";
import translationZhTw from "@/translations/zh-tw.json";
import { AppState, LoginInfo } from "@/types";
import { encodeData, finishLoading, loggedIn, showDialog } from "@/utils";
import { connectDatabase, setWallpaper } from "@/wallpaper";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlipay,
	faGithub,
	faPaypal,
	faWeixin,
} from "@fortawesome/free-brands-svg-icons";
import {
	faAngleLeft,
	faAngleRight,
	faArrowRightArrowLeft,
	faBan,
	faBars,
	faChartSimple,
	faCheck,
	faCircleCheck,
	faCircleExclamation,
	faCircleInfo,
	faCirclePlus,
	faCircleQuestion,
	faCircleUser,
	faCode,
	faDownload,
	faFileCode,
	faFileExport,
	faFileImport,
	faFileZipper,
	faFolderOpen,
	faGear,
	faGlobe,
	faHeart,
	faHouseLaptop,
	faKey,
	faLink,
	faListCheck,
	faLock,
	faPause,
	faPenToSquare,
	faPlus,
	faPowerOff,
	faRightFromBracket,
	faRobot,
	faSave,
	faShieldHalved,
	faSpinner,
	faSquareArrowUpRight,
	faTerminal,
	faTimes,
	faTrashCan,
	faTriangleExclamation,
	faUpload,
	faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import { FocusTrap } from "focus-trap-vue";
import i18next, { t } from "i18next";
import I18NextVue from "i18next-vue";
import { createApp } from "vue";
import VueGtagPlugin, { bootstrap } from "vue-gtag";
import VueMarkdown from "vue-markdown-render";

const i18nResources = {
	"en-US": {
		translation: translationEnUs,
	},
	"zh-CN": {
		translation: translationZhCn,
	},
	"zh-TW": {
		translation: translationZhTw,
	},
};

const lang = ((): string => {
	if (
		globals.settings.language &&
		i18nResources[globals.settings.language as keyof typeof i18nResources]
	) {
		return globals.settings.language;
	} else if (
		!navigator.language ||
		/^(yue|zh)(-cn|-sg|-hans(-[a-z]+)?)?$/i.test(navigator.language)
	) {
		return "zh-CN";
	} else if (
		navigator.language.startsWith("zh") ||
		navigator.language.startsWith("yue")
	) {
		return "zh-TW";
	} else {
		return "en-US";
	}
})();

window.addEventListener("dragover", (event) => {
	event.preventDefault();
});

window.addEventListener("drop", (event) => {
	event.preventDefault();
	void handleDrop(event);
});

window.addEventListener("keydown", (event) => {
	const key = event.key.toLowerCase();
	if (event.ctrlKey || event.metaKey) {
		const dispatchToCodeEditor = (): boolean => {
			if (
				editorInstance &&
				document.activeElement &&
				document.activeElement.nodeName !== "TEXTAREA" &&
				!document.getElementsByClassName("popup").length
			) {
				editorInstance.focus();
				setTimeout(() => {
					document
						.getElementsByClassName("monaco-editor")[0]
						.dispatchEvent(event);
				}, 1);
				return true;
			}
			return false;
		};

		switch (key) {
			case "backspace": {
				event.preventDefault();
				if (globals.state.currentFile) {
					void deleteFile(globals.state.currentFile);
				}
				break;
			}
			case "0": {
				globals.settings.fontSize = 14;
				changeEditorFontSize(true);
				break;
			}
			case "a":
			case "f": {
				if (dispatchToCodeEditor()) {
					event.preventDefault();
				}
				break;
			}
			case "s": {
				event.preventDefault();
				if (
					!globals.state.currentFile ||
					typeof globals.state.files[globals.state.currentFile] ===
						"string"
				) {
					handleSaveClick();
				}
				break;
			}
			case "=": {
				event.preventDefault();
				if (globals.settings.fontSize < 72) {
					globals.settings.fontSize = globals.settings.fontSize + 2;
					changeEditorFontSize(true);
				}
				break;
			}
			case "-": {
				event.preventDefault();
				if (globals.settings.fontSize > 12) {
					globals.settings.fontSize = globals.settings.fontSize - 2;
					changeEditorFontSize(true);
				}
				break;
			}
			default:
				break;
		}
	} else if (event.altKey) {
		if (event.code === "KeyZ") {
			// event.key would be ω
			event.preventDefault();
			wrap();
		}
	} else if (key === "f2") {
		if (isFileOpened()) {
			void renameFilePrompt(globals.state.currentFile);
		}
	}
});

window.addEventListener("message", (event) => {
	try {
		if (typeof event.data !== "string") {
			return;
		}
		const data = JSON.parse(
			decodeURIComponent(window.atob(event.data)),
		) as {
			action?: string;
			birthday?: string;
			email?: string;
			name?: string;
			text?: string;
			token?: string;
			username?: string;
		};
		switch (data.action) {
			case "alert": {
				if (data.text) {
					void showDialog(data.text);
				}
				break;
			}
			case "login": {
				delete data.action;
				globals.login = data;
				loggedIn(true);
				break;
			}
			default:
				break;
		}
	} catch (error) {
		if (process.env.NODE_ENV === "development") {
			console.warn(event.data, error);
		}
	}
});

window.addEventListener("DOMContentLoaded", () => {
	if (
		!globals.isMonacoSupported ||
		globals.settings.codeEditor === "native" ||
		(!globals.settings.codeEditor && (globals.isMobile || globals.isSafari))
	) {
		const codeTextArea = document.getElementById(
			"code",
		) as HTMLTextAreaElement;
		codeTextArea.hidden = false;
		codeTextArea.setAttribute("aria-label", t("codeEditor"));
		codeTextArea.style.fontSize = globals.settings.fontSize + "px";
		codeTextArea.addEventListener("input", codeChanged);

		globals.loadCount++;
		finishLoading();
	} else {
		enableMonacoEditor(1000)
			.then(() => {
				globals.loadCount++;
				finishLoading();

				let reloadCount = 0;
				const intervalId = setInterval(() => {
					if (globals.state.currentSite || reloadCount >= 2) {
						clearInterval(intervalId);
						return;
					}
					reloadMonacoEditor();
					reloadCount++;
				}, 2000);
			})
			.catch(console.error);
	}
});

window.addEventListener("load", () => {
	setTimeout(() => {
		void bootstrap();
	}, 1000);
	loadDependencies();
});

void (async (): Promise<void> => {
	if (
		process.env.NODE_ENV === "production" &&
		!window.location.hostname?.endsWith(".retiehe.com")
	) {
		window.location.href = "https://host.retiehe.com/";
		return;
	}

	await i18next.init({
		fallbackLng: "en-US",
		interpolation: {
			escapeValue: false,
		},
		lng: lang,
		resources: i18nResources,
	});

	library.add(
		faAlipay,
		faAngleLeft,
		faAngleRight,
		faArrowRightArrowLeft,
		faBan,
		faBars,
		faChartSimple,
		faCheck,
		faCircleCheck,
		faCircleExclamation,
		faCircleInfo,
		faCirclePlus,
		faCircleQuestion,
		faCircleUser,
		faCode,
		faDownload,
		faFileCode,
		faFileExport,
		faFileImport,
		faFileZipper,
		faFolderOpen,
		faGear,
		faGithub,
		faGlobe,
		faHeart,
		faHouseLaptop,
		faKey,
		faLink,
		faListCheck,
		faLock,
		faPause,
		faPaypal,
		faPenToSquare,
		faPlus,
		faPowerOff,
		faRightFromBracket,
		faRobot,
		faSave,
		faShieldHalved,
		faSpinner,
		faSquareArrowUpRight,
		faTerminal,
		faTimes,
		faTrashCan,
		faTriangleExclamation,
		faUpload,
		faWeixin,
		faWrench,
	);

	document.documentElement.lang = lang;
	document.title = t("rthWebHosting");

	if (!navigator.cookieEnabled) {
		window.alert(t("cookieDisabled"));
	}

	const app = createApp(App)
		.component("focus-trap", FocusTrap)
		.component("font-awesome-icon", FontAwesomeIcon)
		.component("add-site-popup", AddSitePopup)
		.component("ai-bar", AiBar)
		.component("ai-icon", AiIcon)
		.component("api-keys-popup", ApiKeysPopup)
		.component("chat-message", ChatMessage)
		.component("context-menu", ContextMenu)
		.component("corner-icons", CornerIcons)
		.component("dialog-popup", DialogPopup)
		.component("file-list", FileList)
		.component("float-notification", FloatNotification)
		.component("header-area", HeaderArea)
		.component("loading-screen", LoadingScreen)
		.component("login-panel", LoginPanel)
		.component("main-menu", MainMenu)
		.component("navigation-bar", NavigationBar)
		.component("new-api-key-popup", NewApiKeyPopup)
		.component("prompt-popup", PromptPopup)
		.component("settings-item", SettingsItem)
		.component("settings-popup", SettingsPopup)
		.component("sidebar-shell", SidebarShell)
		.component("site-list", SiteList)
		.component("splash-screen", SplashScreen)
		.component("ssl-popup", SslPopup)
		.component("toast-popup", ToastPopup)
		.component("upload-center", UploadCenter)
		.component("vue-markdown", VueMarkdown)
		.use(I18NextVue, {
			i18next: i18next,
		})
		.use(VueGtagPlugin, {
			bootstrap: false,
			config: {
				id: "G-Y2GS2Z789S",
			},
		});

	Sentry.init({
		app: app,
		dsn: "https://ebbd589f6cf75997100a42b97bbe7d21@o4508388789387264.ingest.us.sentry.io/4508413160456192",
	});

	const instance = app.mount("#app");
	globals.state = instance.$data as AppState;

	try {
		const data = await api.getInit();
		globals.dynamicInfo = data;
		globals.dynamicInfo.loaded = true;
		if (data.login) {
			try {
				globals.login = JSON.parse(data.login) as LoginInfo;
				loggedIn(false);
			} catch (error) {
				console.error(error);
			}
		} else {
			globals.state.isLoginPanelShown = true;
			try {
				const data = await api.getIntro();
				if (data && !globals.state.currentSite) {
					setValue(data);
				}
			} catch (error) {
				void api.handleApiError(error);
			} finally {
				finishLoading();
			}
		}
		if (data.blockReason) {
			window.location.href =
				"https://account.retiehe.com/blocked?" +
				encodeData({
					reason: data.blockReason,
				});
			return;
		}
		if (data.redirectTo) {
			window.location.href = data.redirectTo;
			return;
		}
		if (data.alert) {
			void showDialog(data.alert);
		}
	} catch (error) {
		console.error(error);
		void showDialog(t("essentialComponentNotLoaded"));
	}

	globals.themeColor.name = "theme-color";
	globals.themeColor.content = globals.isLight ? "#ffffff" : "#000000";
	document.head.appendChild(globals.themeColor);

	if (globals.isLight) {
		document.documentElement.classList.add("light");
	}
	if (globals.settings.theme === "wallpaper") {
		try {
			const database = await connectDatabase();
			const dbName = "wallpaper";
			database
				.transaction(dbName)
				.objectStore(dbName)
				.get("custom").onsuccess = function (): void {
				setWallpaper(this.result);
			};
		} catch (error) {
			console.error(error);
		}
	}

	if (!/bot|spider/i.test(navigator.userAgent)) {
		try {
			const data = await api.checkBrowser();
			globals.browserCheck.grade = data.grade;
			globals.browserCheck.isBadDns = data.isBadDns;
			if (data.isBrowser && data.grade <= 30) {
				await showDialog(data.description, {
					isHtml: true,
					showCancel: true,
				});
				window.open("https://check.retiehe.com/");
			}
		} catch (error) {
			void api.handleApiError(error);
		}
	}
})();
