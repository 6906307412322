<script setup lang="ts">
import * as api from "@/api";
import globals from "@/globals";
import { ApiKeyInfo } from "@/types";
import { getPremium, showDialog, sleep } from "@/utils";
import { t } from "i18next";
import { onMounted, ref } from "vue";

const apiKeys = ref<ApiKeyInfo[]>([]);
const isClosing = ref<boolean>(false);
const isNewApiKeyPopupShown = ref<boolean>(false);
const newApiKey = ref<string>("");

async function closeApiKeysPopup(): Promise<void> {
	if (isNewApiKeyPopupShown.value) {
		return;
	}
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isApiKeysPopupShown = false;
	isClosing.value = false;
}

function closeNewApiKeyPopup(): void {
	newApiKey.value = "";
	isNewApiKeyPopupShown.value = false;
}

async function createApiKey(): Promise<void> {
	if (!globals.privilege) {
		await showDialog(
			t("premiumRequiredForAutoDeploy") + t("refreshIfJustPaid"),
			{
				showCancel: true,
			},
		);
		void getPremium();
		return;
	}
	globals.state.isLoadingScreenShown = true;
	try {
		const data = await api.createApiKey();
		newApiKey.value = data.apiKey;
		apiKeys.value.push({
			apiKey:
				data.apiKey.substring(0, 8) + "..." + data.apiKey.substring(28),
			createdAt: data.createdAt,
			id: data.id,
		});
		isNewApiKeyPopupShown.value = true;
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

async function deleteApiKey(id: string): Promise<void> {
	await showDialog(t("confirmDeleteThisApiKey"), {
		showCancel: true,
	});
	globals.state.isLoadingScreenShown = true;
	try {
		await api.deleteApiKey(id);
		apiKeys.value = apiKeys.value.filter((item) => {
			return item.id !== id;
		});
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

async function toggleApiKey(apiKeyItem: ApiKeyInfo): Promise<void> {
	globals.state.isLoadingScreenShown = true;
	try {
		if (apiKeyItem.isDisabled) {
			await api.enableApiKey(apiKeyItem.id);
		} else {
			await api.disableApiKey(apiKeyItem.id);
		}
		apiKeyItem.isDisabled = !apiKeyItem.isDisabled;
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

onMounted(async () => {
	globals.state.isLoadingScreenShown = true;
	try {
		const data = await api.getApiKeys();
		apiKeys.value = data.results;
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
});
</script>

<template>
	<focus-trap
		v-if="!isNewApiKeyPopupShown"
		:active="true"
		@deactivate="closeApiKeysPopup"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
		>
			<div
				class="popup"
				:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
				role="dialog"
			>
				<h1>{{ $t("apiKeys") }}</h1>
				<p>
					{{ $t("apiKeyIntroduction") }}
					<a
						href="https://docs.retiehe.com/host/auto-deploy.html"
						target="_blank"
						rel="noopener"
					>
						{{ $t("viewTutorial") }}
					</a>
				</p>
				<button
					type="button"
					@click="createApiKey()"
				>
					<font-awesome-icon icon="plus" />{{ $t("newKey") }}
				</button>
				<div class="table-container">
					<table>
						<thead>
							<tr>
								<th>{{ $t("apiKeys") }}</th>
								<th>{{ $t("created") }}</th>
								<th>{{ $t("lastUsed") }}</th>
								<th>{{ $t("actions") }}</th>
							</tr>
						</thead>
						<tbody v-if="apiKeys.length === 0">
							<tr>
								<td>
									{{ $t("youHaveNotCreatedAnyApiKeysYet") }}
								</td>
							</tr>
						</tbody>
						<tbody v-if="apiKeys.length > 0">
							<tr
								v-for="item in apiKeys"
								:key="item.id"
							>
								<td>{{ item.apiKey }}</td>
								<td>
									{{
										new Date(
											item.createdAt,
										).toLocaleDateString()
									}}
								</td>
								<td>
									{{
										item.lastUsedAt
											? new Date(
													item.lastUsedAt,
												).toLocaleDateString()
											: "-"
									}}
								</td>
								<td>
									<button
										type="button"
										@click="toggleApiKey(item)"
									>
										{{
											item.isDisabled
												? $t("enable")
												: $t("disable")
										}}
									</button>
									<button
										type="button"
										@click="deleteApiKey(item.id)"
									>
										{{ $t("delete") }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="btn-bar">
					<button
						class="cancel-btn default-btn"
						type="button"
						@click="closeApiKeysPopup()"
					>
						{{ $t("ok") }}
					</button>
				</div>
			</div>
		</div>
	</focus-trap>
	<new-api-key-popup
		v-if="isNewApiKeyPopupShown"
		:close-new-api-key-popup="closeNewApiKeyPopup"
		:is-closing="isClosing"
		:new-api-key="newApiKey"
	/>
</template>

<style scoped>
button {
	margin: 0;
}

button svg {
	margin-right: 6px;
}

p {
	font-size: 14px;
}

table {
	border-collapse: collapse;
	font-size: 14px;
	text-align: left;
	width: 100%;
}

tbody tr {
	border-bottom: 1px solid var(--fg-alpha-05);
}

td button {
	padding: 5px 10px;
	width: auto;
}

td button:not(:first-of-type) {
	margin-left: 5px;
}

td,
th {
	padding: 5px 10px;
}

thead {
	background-color: var(--fg-alpha-05);
}

.table-container {
	margin-top: 15px;
	max-height: 200px;
	overflow: auto;
	white-space: nowrap;
}
</style>
