import * as api from "@/api";
import {
	clearModels,
	createModel,
	createModels,
	deleteModels,
	editorInstance,
	getValue,
	readMode,
	setValue,
} from "@/code-editor";
import { importPizZip } from "@/dependencies";
import globals from "@/globals";
import { showSettings } from "@/settings";
import { getDomain, getSuffix, isSiteOpened, rmBackup } from "@/site";
import { closeTerminal, parsePackageJson } from "@/terminal";
import {
	BinaryFile,
	Column,
	FileUploadInfo,
	FileUploadStatus,
	PresetSaveCallback,
	SiteConfig,
	SiteContent,
	UploadResponse,
	UploadSiteRequest,
} from "@/types";
import {
	closeAiBar,
	closeDialog,
	getPremium,
	getRthCdnBaseUrl,
	showDialog,
	showFloatNotification,
	showPrompt,
	showToast,
	sleep,
} from "@/utils";
import { t } from "i18next";
import PizZip from "pizzip";

const fileInput = ((): HTMLInputElement => {
	const newInput = document.createElement("input");
	newInput.type = "file";
	return newInput;
})();

function checkForeignCdn(): boolean {
	const cdnDomains = [
		"cdnjs.cloudflare.com",
		"cdn.jsdelivr.net",
		"cdn.tailwindcss.com",
		"code.jquery.com",
		"googleapis.com",
		"unpkg.com",
	];
	const value = getValue();
	for (const domain of cdnDomains) {
		if (value.includes(domain)) {
			showFloatNotification({
				text: t("usingForeignPublicCdn", {
					cdnDomain: domain,
				}),
				title: t("warning"),
			});
			return true;
		}
	}
	return false;
}

export function configExists(config?: SiteConfig): config is SiteConfig {
	if (!config) {
		void showDialog(t("configNotFound"));
		return false;
	}
	return true;
}

export async function deleteFile(name: string): Promise<void> {
	if (globals.state.selectedFiles.length > 0) {
		await showDialog(t("confirmDeleteMultipleFiles"), {
			showCancel: true,
		});
		const config = globals.state.files["settings.rth"];
		if (!configExists(config)) {
			return;
		}
		const filesToBeDeleted = globals.state.selectedFiles.concat([
			globals.state.currentFile,
		]);
		for (let i = 0; i < filesToBeDeleted.length; i++) {
			delete globals.state.files[filesToBeDeleted[i]];
		}
		deleteModels(filesToBeDeleted);
		void save({
			keys: filesToBeDeleted,
		});
		globals.state.selectedFiles = [];
		void openFile(config.home);
	} else {
		await showDialog(
			t("confirmDelete", {
				name: name,
			}),
			{
				showCancel: true,
			},
		);
		const config = globals.state.files["settings.rth"];
		if (!configExists(config)) {
			return;
		}
		const filesToBeDeleted = [name];
		const isFolder = name.endsWith("/");
		delete globals.state.files[name];
		if (isFolder) {
			for (const key in globals.state.files) {
				if (
					key !== name &&
					key !== config.home &&
					key.startsWith(name)
				) {
					delete globals.state.files[key];
					filesToBeDeleted.push(key);
				}
			}
		} else if (name.toLowerCase().endsWith(".md")) {
			delete globals.state.files[name + ".html"];
		}
		deleteModels(filesToBeDeleted);
		void save({
			keys: filesToBeDeleted,
		});
		void openFile(config.home);
	}
}

export function fileExists(
	filename: string,
	options: {
		isNegative?: boolean;
		noDialog?: boolean;
	} = {},
): boolean {
	if (!globals.accept.test(filename) && !globals.privilege) {
		if (!options.noDialog) {
			showDialog(
				t("premiumRequiredForCreatingThisFileType") +
					t("refreshIfJustPaid"),
				{
					showCancel: true,
				},
			)
				.then(getPremium)
				.catch(console.error);
		}
		return true;
	} else if (globals.state.files[filename] !== undefined) {
		if (!options.isNegative && !options.noDialog) {
			void closeDialog();
			void showDialog(
				t("error") +
					t("alreadyExists", {
						filename: filename,
					}),
			);
		}
		return true;
	} else {
		if (options.isNegative && !options.noDialog) {
			void showDialog(
				t("error") +
					t("doesNotExist", {
						filename: filename,
					}),
			);
		}
		return false;
	}
}

export function getFolderName(filename: string): string {
	if (!filename.includes("/")) {
		return "";
	}
	const split = filename.split("/");
	split.pop();
	return split.join("/") + "/";
}

export function getUrl(
	isAlwaysHttps: boolean,
	filename = globals.state.currentFile,
	fullDomain = globals.state.currentSite,
): string {
	const config = globals.state.files["settings.rth"];
	if (filename && configExists(config) && filename === config.home) {
		filename = "";
	}
	const domain = getDomain(fullDomain);
	const suffix = getSuffix(domain);
	return (
		(isAlwaysHttps || !domain.includes(".") ? "https:" : "http:") +
		"//" +
		domain +
		suffix +
		"/" +
		filename
	);
}

export function getValidFilename(value: string, extension = ".html"): string {
	value = value
		.replaceAll("\\", "/")
		.replace(/^\/+/, "") // remove leading slashes
		.replace(/\/+/g, "/") // replace multiple slashes with one
		.replace(/\.+/g, ".")
		.replace(/[^\d\w\u4e00-\u9fa5\-/.]/g, "");
	if (extension === "/" || (!value.includes(".") && !value.endsWith("/"))) {
		value += extension;
	}
	if (value === ".html") {
		value = "untitled" + value;
	} else if (value.endsWith(".md.html")) {
		value = value.substring(0, value.length - 5);
	}
	const maxLen = 255;
	if (value.length > maxLen) {
		value = value.substring(value.length - maxLen);
	}
	return value;
}

export function handleSaveClick(): void {
	if (isFileOpened()) {
		const executable = /\.(html?|md|php)$/i;
		void save({
			callback:
				!parsePackageJson() &&
				executable.test(globals.state.currentFile)
					? PresetSaveCallback.SHOW_DIALOG
					: PresetSaveCallback.SHOW_TOAST,
			key: globals.state.currentFile,
		});
	}
}

export function isFileOpened(): boolean {
	if (globals.state.currentFile) {
		return true;
	} else {
		void showDialog(t("notOpenedFile"));
		return false;
	}
}

export function isImg(filename: string): boolean {
	const file = globals.state.files[filename] as BinaryFile;
	return (
		Boolean(file) &&
		(Boolean(file.hash) || file.type?.startsWith("image/") || false)
	);
}

export function isRenamable(name: string): boolean {
	return (
		globals.state.selectedFiles.length === 0 &&
		(!!globals.privilege ||
			name !== globals.state.files["settings.rth"]?.home)
	);
}

function moveFile(oldName: string, newName: string): void {
	globals.state.files[newName] = globals.state.files[oldName];
	delete globals.state.files[oldName];
}

export async function openFile(
	name: string,
	event?: MouseEvent,
): Promise<void> {
	let hasFileOpened = false;
	globals.state.currentFile = name;
	await closeDialog();
	for (const key in globals.state.files) {
		if (key !== name) {
			continue;
		}
		const currentFile = globals.state.files[globals.state.currentFile];
		if (currentFile === undefined) {
			break;
		}
		hasFileOpened = true;
		if (event && (!name.endsWith("/") || currentFile)) {
			globals.state.currentColumn = Column.CodeEditor;
		}
		document.getElementsByTagName("img")[0]?.remove();
		const mainArea = document.getElementById("main") as HTMLElement;
		if (typeof currentFile === "string") {
			mainArea.classList.remove("img");
			createModels(globals.state.files);
			setValue(currentFile, name);
			readMode();
			checkForeignCdn();
		} else {
			void closeAiBar();
			mainArea.classList.add("img");
			if (isImg(globals.state.currentFile)) {
				const currentImage = currentFile as BinaryFile;
				const newImg = new Image();
				if (currentImage.url?.startsWith("anonymous/")) {
					newImg.src = getUrl(true);
				} else {
					newImg.src =
						getRthCdnBaseUrl() +
						((): string => {
							const fingerprint =
								currentImage.md5 || currentImage.hash;
							if (fingerprint) {
								return "cached-" + fingerprint;
							} else if (
								currentImage.url &&
								!currentImage.url.includes(":")
							) {
								return (
									"cached-" + currentImage.url.split("/")[2]
								);
							} else {
								return "0";
							}
						})() +
						"/" +
						getDomain(globals.state.currentSite) +
						"/" +
						globals.state.currentFile;
				}
				newImg.alt = globals.state.currentFile;
				document
					.getElementsByClassName("img-frame")[0]
					.appendChild(newImg);
			} else {
				await showDialog(t("confirmDownloadThisFile"), {
					showCancel: true,
				});
				visitPage();
			}
		}
	}
	if (!hasFileOpened) {
		globals.state.currentFile = "";
		setValue("");
	}
}

export async function prepareUpload(
	files: File[],
	paths?: string[],
): Promise<void> {
	if (files.length === 1 && files[0].name.endsWith(".zip")) {
		readZipFile(files[0]); // will call prepareUpload again
		return;
	}
	const pendingUploadFilesInfo: FileUploadInfo[] = [];
	globals.pendingUploadFiles = {};
	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const path = paths ? paths[i] : file.name;
		globals.pendingUploadFiles[path] = file;
		pendingUploadFilesInfo.push({
			path: path,
			skipReason: "",
			status: FileUploadStatus.NOT_UPLOADED,
			type: file.type,
		});
	}
	globals.state.pendingUploadFiles = [];
	await sleep(1);
	globals.state.pendingUploadFiles = pendingUploadFilesInfo;
}

export function readTextFile(file: Blob): Promise<string> {
	return new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.onload = (): void => {
			if (typeof reader.result === "string") {
				resolve(reader.result);
			}
		};
		reader.readAsText(file);
	});
}

export function readZipFile(file: File): void {
	const deleteCommonPrefix = (array: string[]): void => {
		if (array.length === 0) {
			return;
		}
		const prefix = array[0].split("/")[0];
		for (let i = 1; i < array.length; i++) {
			if (array[i].split("/")[0] !== prefix) {
				return;
			}
		}
		for (let i = 0; i < array.length; i++) {
			array[i] = array[i].substring(prefix.length + 1);
		}
	};

	const reader = new FileReader();
	reader.onload = async (): Promise<void> => {
		try {
			const PizZip = await importPizZip();
			const zip = new PizZip(reader.result as PizZip.LoadData);
			const files: File[] = [];
			const names: string[] = [];
			for (const filename in zip.files) {
				if (
					filename.startsWith("__MACOSX/") ||
					filename.includes("node_modules")
				) {
					continue;
				}
				const file = zip.files[filename];
				const filenameLower = filename.toLowerCase();
				if (
					!file.dir &&
					(filenameLower.endsWith(".php") ||
						(globals.accept.test(filenameLower) &&
							!filenameLower.endsWith(".rth")))
				) {
					const fileContent = file.asText();
					if (fileContent) {
						files.push(
							new File(
								[fileContent],
								filename.split("/").pop() || filename,
							),
						);
						names.push(filename);
					}
				}
			}
			deleteCommonPrefix(names);
			void prepareUpload(files, names);
		} catch (error) {
			console.error(error);
			void showDialog(t("unableReadThisZipFile"));
		}
	};
	reader.readAsArrayBuffer(file);
}

function renameFile(oldName: string, newName: string): void {
	if (oldName === newName) {
		return;
	}
	const config = globals.state.files["settings.rth"];
	if (!configExists(config)) {
		return;
	}
	const isFolder = oldName.endsWith("/");
	newName = isFolder
		? getValidFilename(newName, "/")
		: getValidFilename(newName);
	if (fileExists(newName)) {
		return;
	}
	moveFile(oldName, newName);
	if (isFolder) {
		for (const key in globals.state.files) {
			if (key !== oldName && key !== newName && key.startsWith(oldName)) {
				const newChildName = newName + key.substring(oldName.length);
				if (!fileExists(newChildName)) {
					moveFile(key, newChildName);
				}
			}
		}
		clearModels(true);
		createModels(globals.state.files);
	} else {
		const file = globals.state.files[newName];
		if (typeof file === "string") {
			deleteModels([oldName]);
			createModel(newName, file);
		}
	}
	if (oldName === config.home) {
		config.home = newName;
	}
	if (oldName.endsWith(".js") && globals.state.files[oldName + ".rth"]) {
		moveFile(oldName + ".rth", newName + ".rth");
	} else if (
		oldName.endsWith(".md") &&
		globals.state.files[oldName + ".html"]
	) {
		moveFile(oldName + ".html", newName + ".html");
	}
}

export async function renameFilePrompt(name: string): Promise<void> {
	const isFolder = name.endsWith("/");
	const defaultText = isFolder ? name.substring(0, name.length - 1) : name;
	const newName = await showPrompt(t("enterNewFilename"), {
		defaultText: defaultText,
	});
	if (!newName) {
		return;
	}
	renameFiles(new Map([[name, newName]]));
}

export function renameFiles(nameMap: Map<string, string>): void {
	for (const [oldName, newName] of nameMap) {
		renameFile(oldName, newName);
	}
	void save({
		callback: () => {
			const firstNewName = nameMap.values().next().value as string;
			void openFile(firstNewName);
		},
		content: Object.fromEntries(nameMap),
		type: "rename",
	});
}

export function resetFileList(): void {
	globals.state.changedFiles = [];
	globals.state.currentFile = "";
	globals.state.currentFolder = "";
	globals.state.files = {} as SiteContent;
	globals.state.hasUnsaved = false;
	globals.state.pendingUploadFiles = [];
	globals.state.selectedFiles = [];
	globals.pendingUploadFiles = {};
	closeTerminal();
}

export async function save({
	callback,
	content,
	hideLoading,
	key,
	keys,
	skipLocalCopy,
	type,
}: {
	callback?: (() => void) | PresetSaveCallback;
	content?: string | File | BinaryFile | SiteConfig | SiteContent;
	hideLoading?: boolean;
	key?: string;
	keys?: string[];
	skipLocalCopy?: boolean;
	type?: "object" | "rename" | "string" | "undefined";
} = {}): Promise<boolean> {
	if (!isSiteOpened()) {
		return false;
	}
	if (key) {
		globals.state.changedFiles = globals.state.changedFiles.filter(
			(item) => {
				return item !== key;
			},
		);
	} else {
		globals.state.changedFiles = [];
	}
	globals.state.hasUnsaved = false;
	globals.currentHostInitial = JSON.parse(
		JSON.stringify(globals.state.files),
	) as SiteContent;
	const hasLocalCopy =
		!skipLocalCopy &&
		globals.socket &&
		globals.socket.readyState === WebSocket.OPEN;
	const postData: UploadSiteRequest = {
		content: undefined,
		domain: encodeURIComponent(getDomain(globals.state.currentSite)),
		key: "",
		keys: "",
		privilege: globals.privilege || undefined,
		processor: undefined,
		type: "",
	};
	let keepFloat = false;
	if (type === "rename") {
		content = content as SiteContent;
		postData.content = JSON.stringify(content);
		postData.type = "rename";
		if (hasLocalCopy) {
			try {
				for (const oldName in content) {
					const newName = content[oldName] as string;
					await api.renameTerminalFile(oldName, newName);
				}
			} catch (error) {
				void api.handleApiError(error);
			}
		}
	} else if (key) {
		// save a single file
		if (key.includes("/")) {
			// create non-existing folders
			const folderName = getFolderName(key);
			if (!globals.state.files[folderName]) {
				globals.state.files[folderName] = "";
			}
		}
		postData.key = key;
		if (typeof content === "undefined") {
			// read file content from state
			content = globals.state.files[key];
		}
		postData.type = type || typeof content;
		if (typeof content === "object" && !(content instanceof File)) {
			content = JSON.stringify(content);
		}
		postData.content = content; // not only string
		if (typeof content === "string") {
			if (key.endsWith(".js")) {
				if (content.includes("jsjiami.com")) {
					keepFloat = true;
					showFloatNotification({
						onClick: showSettings,
						text: t("hasJsEncryptorBuiltIn"),
						title: t("manualJsEncryptionNotNeeded"),
					});
				} else if (globals.settings.jsProcessor) {
					postData.processor = globals.settings.jsProcessor;
				}
			} else if (/\.(html?|php)$/i.test(key)) {
				const atAttr = /\s@[a-z]+=/.exec(content);
				if (atAttr) {
					const attr = atAttr[0].slice(2, -1);
					let tip = t("isNonStandardHtmlAttribute", {
						attr: "@" + attr,
					});
					let correctAttr;
					if (content.includes("vue")) {
						correctAttr = "v-on:" + attr;
					} else if (content.includes("alpinejs")) {
						correctAttr = "x-on:" + attr;
					}
					if (correctAttr) {
						tip +=
							t("space") +
							t("useInstead", {
								sth: correctAttr,
							});
					}
					void showDialog(tip, {
						title: t("failedToSave").toString(),
					});
					return false;
				}
			}
			if (hasLocalCopy) {
				try {
					if (!key.endsWith("/") && !key.includes("..")) {
						void api.uploadFileToTerminal(key, content);
					}
				} catch (error) {
					void api.handleApiError(error);
				}
			}
		}
	} else if (keys) {
		// delete files
		postData.keys = JSON.stringify(keys);
		postData.type = "undefined";
		if (hasLocalCopy) {
			try {
				void api.deleteTerminalFiles(keys);
			} catch (error) {
				void api.handleApiError(error);
			}
		}
	} else {
		// save all
		postData.content = JSON.stringify(globals.state.files);
	}
	if (!hideLoading) {
		globals.state.isLoadingScreenShown = true;
	}
	try {
		const data = await api.uploadSite(postData);
		globals.state.isLoadingScreenShown = false;
		if (uploadCallback(data, keepFloat)) {
			// saved successfully
			rmBackup();
			if (callback) {
				if (typeof callback === "function") {
					callback();
				} else if (callback === PresetSaveCallback.SHOW_DIALOG) {
					const isForeignCdnUsed = checkForeignCdn();
					if (isForeignCdnUsed) {
						await sleep(globals.ANIMATION_WAIT_TIME);
					}
					void showDialog(
						t("confirmOpenWebPage"),
						{
							showCancel: true,
						},
						{
							cancel: () => {
								editorInstance?.focus();
							},
							ok: () => {
								globals.openedPage?.close();
								globals.openedPage = visitPage();
							},
						},
					);
				} else if (callback === PresetSaveCallback.SHOW_TOAST) {
					void showToast(t("saved"));
				}
			}
		}
	} catch (error) {
		void api.handleApiError(error);
		globals.state.isLoadingScreenShown = false;
	}
	return true;
}

export function selectLocalFile(
	{ accept, multiple }: { accept: string; multiple: boolean },
	callback: ((files: FileList) => void | Promise<void>) | null = null,
): void {
	fileInput.accept = accept;
	fileInput.multiple = multiple;
	fileInput.onchange = (): void => {
		if (fileInput.files) {
			if (callback) {
				void callback(fileInput.files);
			} else {
				void prepareUpload([...fileInput.files]);
			}
		}
	};
	fileInput.value = "";
	fileInput.click();
}

export function uploadCallback(
	data: UploadResponse,
	keepFloat = false,
): boolean {
	if (!data) {
		return false;
	}
	if (data.errorLog) {
		console.error(data.errorLog);
		showFloatNotification({
			isFolded: true,
			text: data.errorLog,
			title: t("jsProcessorError"),
		});
	} else if (!keepFloat) {
		globals.state.floatNotificationInfo = null;
	}
	if (data.modified) {
		for (const key in data.modified) {
			globals.state.files[key] = data.modified[key];
		}
	}
	if (data.alert) {
		void showDialog(t(data.alert), {
			title: t("failedToSave").toString(),
		});
	}
	return Boolean(data.success);
}

export function visitPage(
	filename = globals.state.currentFile,
	fullDomain = globals.state.currentSite,
): Window | null {
	try {
		return window.open(getUrl(false, filename, fullDomain));
	} catch (error: unknown) {
		console.error(error);
		if (error instanceof Error) {
			void showDialog(error.message);
		}
	}
	return null;
}
