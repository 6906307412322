<script setup lang="ts">
import globals from "@/globals";
import { ToastInfo } from "@/types";
import { onMounted, onUnmounted, ref } from "vue";

const props = defineProps<{
	isClosingToast: boolean;
	toast: ToastInfo;
}>();

const timeoutId = ref<number | null>(null);

function closeToast(): void {
	globals.state.isClosingToast = true;
	setTimeout(() => {
		globals.state.toast = {};
		globals.state.isClosingToast = false;
	}, globals.ANIMATION_WAIT_TIME);
}

onMounted(() => {
	timeoutId.value = window.setTimeout(
		closeToast,
		props.toast.timeout || 3000,
	);
});

onUnmounted(() => {
	if (timeoutId.value) {
		window.clearTimeout(timeoutId.value);
	}
});
</script>

<template>
	<div
		class="toast"
		:class="{ closing: isClosingToast }"
	>
		{{ toast.text }}
	</div>
</template>

<style scoped>
.toast {
	animation: slide-in 0.25s;
	background-color: var(--theme-color);
	border-radius: 10px;
	bottom: 60px;
	color: white;
	font-size: 14px;
	left: 50%;
	padding: 8px 15px;
	position: fixed;
	transform: translateX(-50%);
	z-index: 1;
}

.toast.closing {
	animation: slide-out 0.25s forwards;
}

@keyframes slide-in {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(50%);
	}
}

@keyframes slide-out {
	100% {
		opacity: 0;
		transform: translateX(-50%) translateY(50%);
	}
}
</style>
