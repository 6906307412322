<script setup lang="ts">
import { createModel } from "@/code-editor";
import {
	deleteFile,
	fileExists,
	getFolderName,
	getValidFilename,
	isImg,
	isRenamable,
	openFile,
	renameFilePrompt,
	renameFiles,
	save,
	selectLocalFile,
	visitPage,
} from "@/file";
import globals from "@/globals";
import { isSiteOpened } from "@/site";
import { SiteContent } from "@/types";
import {
	downloadText,
	handleKeyboardClick,
	showContextMenu,
	showDialog,
	showPrompt,
	showToast,
	sleep,
} from "@/utils";
import { t } from "i18next";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

const props = defineProps<{
	changedFiles: string[];
	currentFile: string;
	currentFolder: string;
	currentSite: string;
	files: SiteContent;
	isSelected: boolean;
	selectedFiles: string[];
}>();

const isMultiSelectMode = ref<boolean>(false);

const filteredFilenames = computed(() => {
	const completedFilenames = fillMissingFolderNames(Object.keys(props.files));
	const filenames = [];
	const folderNames = [];
	const sorted = completedFilenames.sort().filter((item) => {
		if (item.endsWith("/")) {
			item = item.slice(0, -1);
		}
		const folder = getFolderName(item);
		return props.currentFolder === folder;
	});
	for (const name of sorted) {
		if (
			name === "settings.rth" ||
			name.endsWith(".js.rth") ||
			name.endsWith(".md.html") ||
			name.endsWith(".ts.rth")
		) {
			continue;
		}
		if (name.endsWith("/")) {
			folderNames.push(name);
		} else {
			filenames.push(name);
		}
	}
	return folderNames.concat(filenames);
});

const pathParts = computed(() => {
	const parts = props.currentFolder.split("/");
	parts.pop();
	return parts;
});

function clearSelectedFiles(): void {
	globals.state.selectedFiles = [];
	isMultiSelectMode.value = false;
}

function clicked(name: string, event?: MouseEvent): void {
	if (props.files[name] === undefined) {
		// for pesudo folders
		if (name.endsWith("/")) {
			globals.state.currentFolder = name;
		}
		return;
	}
	if (
		globals.isMobile ||
		(typeof props.files[name] !== "string" && !isImg(name))
	) {
		rightClicked(name, event);
		return;
	}
	if (
		isMultiSelectMode.value ||
		(event && (event.ctrlKey || event.metaKey || event.shiftKey))
	) {
		selectFile(name, event);
		return;
	}
	globals.state.selectedFiles = [];
	void openFile(name, event);
	globals.state.currentFolder = getFolderName(name);
}

function fillMissingFolderNames(filenames: string[]): string[] {
	const completedFilenames = new Set<string>(filenames);
	for (const key of filenames) {
		const parts = key.split("/");
		for (let i = 1; i < parts.length; i++) {
			const folderPath = parts.slice(0, i).join("/") + "/";
			completedFilenames.add(folderPath);
		}
	}
	return [...completedFilenames];
}

function getDisplayedFilename(path: string): string {
	if (path.endsWith("/")) {
		path = path.slice(0, -1);
	}
	if (path.includes("/")) {
		path = path.split("/").at(-1) || "";
	}
	if (!path) {
		return t("untitled");
	}
	return path;
}

function getParentFolderPath(): string {
	let path = pathParts.value.slice(0, -1).join("/");
	if (path) {
		path += "/";
	}
	return path;
}

function goToFolder(endIndex: number): void {
	if (endIndex === pathParts.value.length) {
		return;
	}
	let path = pathParts.value.slice(0, endIndex).join("/");
	if (path) {
		path += "/";
	}
	globals.state.currentFolder = path;
}

function handleKeyDown(event: KeyboardEvent): void {
	if (event.key === "Escape") {
		clearSelectedFiles();
	}
}

async function moveFiles(): Promise<void> {
	const folders = Object.keys(props.files)
		.filter((file) => {
			return file.endsWith("/");
		})
		.map((folder) => {
			return folder.substring(0, folder.length - 1);
		});
	let newFolderName = await showPrompt(
		t("enterDestinationFolderNameToWhichMove"),
		{
			dataList: folders,
		},
	);
	newFolderName = getValidFilename(newFolderName, "/");
	const oldToNewNames = new Map<string, string>();
	const selectedFiles = [...props.selectedFiles];

	// if the current file is a folder, it is not in the view; do not select it
	if (!props.currentFile.endsWith("/")) {
		selectedFiles.push(props.currentFile);
	}

	for (const oldName of selectedFiles) {
		if (oldName.endsWith("/")) {
			const newFolder = newFolderName + oldName.split("/").at(-2) + "/";
			oldToNewNames.set(oldName, newFolder);
		} else {
			const newFileName = newFolderName + oldName.split("/").at(-1);
			oldToNewNames.set(oldName, newFileName);
		}
	}

	// check if new names already exist
	for (const newName of oldToNewNames.values()) {
		if (fileExists(newName)) {
			return;
		}
	}

	const newNamesPreview = Array.from(oldToNewNames.entries()).map(
		([oldName, newName]) => {
			return `${oldName} -> ${newName}`;
		},
	);
	await showDialog(
		t("confirmMoveFollowingFiles") + "\n" + newNamesPreview.join("\n"),
		{
			showCancel: true,
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	renameFiles(oldToNewNames);
}

function newFile(event?: MouseEvent): void {
	if (isSiteOpened()) {
		showContextMenu(event, [
			{
				content: {
					icon: "circle-plus",
					text: t("file"),
				},
				onClick: async (): Promise<void> => {
					await sleep(globals.ANIMATION_WAIT_TIME);
					let filename = await showPrompt(t("enterFilename"));
					if (!filename) {
						return;
					}
					if (props.currentFile.includes("/")) {
						filename = props.currentFolder + filename;
					}
					filename = getValidFilename(filename);
					if (!fileExists(filename)) {
						globals.state.files[filename] = "";
						createModel(filename);
						void save({
							key: filename,
							type: "string",
						});
						void openFile(filename);
					}
				},
				when: true,
			},
			{
				content: {
					icon: "circle-plus",
					text: t("folder"),
				},
				onClick: async (): Promise<void> => {
					await sleep(globals.ANIMATION_WAIT_TIME);
					let name = await showPrompt(t("enterFolderName"));
					if (!name) {
						return;
					}
					if (props.currentFile.includes("/")) {
						name = props.currentFolder + name;
					}
					name = getValidFilename(name, "/");
					if (!fileExists(name)) {
						globals.state.files[name] = "";
						void save({
							key: name,
						});
						void openFile(name);
						globals.state.currentFolder = name;
					}
				},
				when: true,
			},
			{
				content: {
					icon: "upload",
					text: t("uploadFile"),
				},
				onClick: (): void => {
					selectLocalFile({
						accept: "",
						multiple: true,
					});
				},
				when: true,
			},
			{
				content: {
					icon: "upload",
					text: t("uploadFolder"),
				},
				onClick: async (): Promise<void> => {
					await sleep(globals.ANIMATION_WAIT_TIME);
					void showDialog(t("dragFolderToUpload"));
				},
				when: !globals.isMobile,
			},
		]);
	}
}

function rightClicked(name: string, event?: MouseEvent): void {
	showContextMenu(event, [
		{
			content: {
				icon: "circle-check",
				text: props.selectedFiles.includes(name)
					? t("unselect")
					: t("select"),
			},
			onClick: (): void => {
				selectFile(name, event);
			},
			when:
				globals.isMobile &&
				isMultiSelectMode.value &&
				name !== props.currentFile,
		},
		{
			content: {
				icon: "folder-open",
				text: t("open"),
			},
			onClick: (): void => {
				void openFile(name, event);
				globals.state.currentFolder = getFolderName(name);
			},
			when:
				globals.isMobile &&
				props.selectedFiles.length === 0 &&
				(typeof props.files[name] === "string" || isImg(name)),
		},
		{
			content: {
				icon: "globe",
				text: t("visit"),
			},
			onClick: (): void => {
				visitPage(name);
			},
			when: props.selectedFiles.length === 0,
		},
		{
			content: {
				icon: "pen-to-square",
				text: t("rename"),
			},
			onClick: async (): Promise<void> => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void renameFilePrompt(name);
			},
			when: isRenamable(name),
		},
		{
			content: {
				icon: "file-export",
				text: t("move"),
			},
			onClick: (): void => {
				void moveFiles();
			},
			when: props.selectedFiles.length > 0,
		},
		{
			content: {
				icon: "download",
				text: t("download"),
			},
			onClick: (): void => {
				const fileContent = props.files[name];
				if (typeof fileContent === "string") {
					downloadText(fileContent, name);
				}
			},
			when:
				props.selectedFiles.length === 0 &&
				typeof props.files[name] === "string",
		},
		{
			content: {
				icon: "trash-can",
				text: t("delete"),
			},
			onClick: async (): Promise<void> => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void deleteFile(name);
			},
			when: true,
		},
		{
			content: {
				icon: "list-check",
				text: isMultiSelectMode.value
					? t("exitMultiSelect")
					: t("multiSelect"),
			},
			onClick: (): void => {
				const newValue = !isMultiSelectMode.value;
				isMultiSelectMode.value = newValue;
				if (newValue) {
					if (name !== props.currentFile) {
						selectFile(name, event);
					}
				} else {
					clearSelectedFiles();
				}
			},
			when: true,
		},
	]);
}

function selectFile(name: string, event?: MouseEvent): void {
	if (name === props.currentFile) {
		clearSelectedFiles();
		return;
	}
	let newSelected: string[];
	if (event?.shiftKey) {
		newSelected = [];
		const currentIndex = filteredFilenames.value.indexOf(props.currentFile);
		const targetIndex = filteredFilenames.value.indexOf(name);
		let max, min;
		if (targetIndex > currentIndex) {
			max = targetIndex;
			min = currentIndex + 1;
		} else {
			max = currentIndex - 1;
			min = targetIndex;
		}
		for (let i = min; i <= max; i++) {
			newSelected.push(filteredFilenames.value[i]);
		}
		isMultiSelectMode.value = true;
	} else {
		newSelected = [...props.selectedFiles];
		if (newSelected.includes(name)) {
			newSelected.splice(newSelected.indexOf(name), 1);
		} else {
			newSelected.push(name);
			isMultiSelectMode.value = true;
		}
	}
	globals.state.selectedFiles = newSelected;
	void showToast(
		t("filesSelected", {
			count: newSelected.length + 1,
		}),
	);
}

onMounted(() => {
	document.addEventListener("keydown", handleKeyDown);
});

onBeforeUnmount(() => {
	document.removeEventListener("keydown", handleKeyDown);
});

watch(
	() => {
		return props.currentFile;
	},
	() => {
		isMultiSelectMode.value = false;
	},
);
</script>

<template>
	<div
		:aria-hidden="!isSelected"
		class="list file-list"
		:class="{ selected: isSelected }"
		role="button"
		tabindex="0"
		:aria-label="$t('clearSelection')"
		@click="clearSelectedFiles"
		@keydown="handleKeyboardClick($event, clearSelectedFiles)"
	>
		<div
			:aria-haspopup="currentSite ? 'menu' : 'dialog'"
			class="head-btn"
			role="button"
			tabindex="0"
			@click="newFile"
			@keydown="handleKeyboardClick($event, () => newFile())"
		>
			<font-awesome-icon icon="plus" />
			<span>{{ $t("newFile") }}</span>
		</div>
		<div class="list-content">
			<ul v-if="filteredFilenames.length > 0 || currentFolder !== ''">
				<template v-if="currentFolder !== ''">
					<li class="breadcrumb">
						<template
							v-for="(part, index) in pathParts"
							:key="index"
						>
							<span
								class="link"
								role="button"
								tabindex="0"
								@click="goToFolder(index + 1)"
								@keydown="
									handleKeyboardClick($event, () =>
										goToFolder(index + 1),
									)
								"
							>
								{{ getDisplayedFilename(part) }} </span
							>{{ "/" }}
						</template>
					</li>
					<li
						class="folder"
						role="menuitem"
						tabindex="0"
						:data-path="getParentFolderPath()"
						@click="goToFolder(-1)"
						@keydown="
							handleKeyboardClick($event, () => goToFolder(-1))
						"
					>
						{{ "../ " + $t("backToParentFolder") }}
					</li>
				</template>
				<li
					v-for="name in filteredFilenames"
					:aria-selected="
						currentFile === name || selectedFiles.includes(name)
					"
					:key="name"
					:class="{
						'changed': changedFiles.includes(name),
						'folder': name.endsWith('/'),
						'multi-select': isMultiSelectMode,
					}"
					draggable="true"
					role="option"
					tabindex="0"
					:data-path="name"
					@click.stop="($event) => clicked(name, $event)"
					@contextmenu.prevent="
						($event) => rightClicked(name, $event)
					"
					@dragstart="() => (globals.dragged = name)"
					@keydown="handleKeyboardClick($event, () => clicked(name))"
				>
					<label
						v-if="isMultiSelectMode"
						@click.prevent
					>
						<input
							type="checkbox"
							:checked="
								currentFile === name ||
								selectedFiles.includes(name)
							"
							@click.prevent
						/>
						<span>{{ getDisplayedFilename(name) }}</span>
					</label>
					<template v-else>
						{{ getDisplayedFilename(name) }}
					</template>
				</li>
			</ul>
			<div
				class="list-placeholder"
				v-else-if="files['settings.rth']"
			>
				{{ $t("noFiles") }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.breadcrumb {
	border: 1px solid var(--fg-alpha-1);
	border-radius: 5px;
	color: var(--fg-alpha-8);
	font-size: 14px;
	margin-bottom: 5px;
}

.multi-select {
	padding: 0;
}

.multi-select input[type="checkbox"] {
	margin-right: 10px;
}

.multi-select > label {
	align-items: center;
	display: flex;
	padding: 5px 10px;
}
</style>
