import * as api from "@/api";
import { reloadMonacoEditor, setValue } from "@/code-editor";
import { importPizZip } from "@/dependencies";
import {
	configExists,
	getFolderName,
	openFile,
	resetFileList,
	save,
} from "@/file";
import globals from "@/globals";
import { Column, PresetSaveCallback, SiteContent } from "@/types";
import { downloadDataUrl, isMainland, showDialog } from "@/utils";
import { t } from "i18next";

export async function exportAsZip(fullDomain: string): Promise<void> {
	const PizZip = await importPizZip();
	const zip = new PizZip();
	const domain = getDomain(fullDomain);
	let hasFailed = false;

	globals.state.isLoadingScreenShown = true;
	zip.file(domain + ".json", JSON.stringify(globals.state.files));
	for (let filename in globals.state.files) {
		const thisFile = globals.state.files[filename];
		if (!thisFile || filename === "settings.rth") {
			continue;
		}
		if (filename.endsWith("/")) {
			if (globals.state.files[filename + "index.html"]) {
				filename += "index-" + Date.now() + ".html";
			} else {
				filename += "index.html";
			}
		}

		const writeThisFile = async (
			zipFolder = zip,
			thisFilename = filename,
		): Promise<void> => {
			try {
				if (typeof thisFile === "string") {
					zipFolder.file(thisFilename, thisFile);
				} else {
					const content = await api.getBinaryFile(domain, filename);
					zipFolder.file(thisFilename, content);
				}
			} catch {
				console.error("Failed to export " + filename);
				hasFailed = true;
			}
		};

		if (filename.includes("/")) {
			const filenameSplit = filename.split("/");

			const createFolder = async (zipFolder = zip): Promise<void> => {
				const folderName = filenameSplit[0];
				const newZipFolder = zipFolder.folder(folderName);
				if (filenameSplit.length > 2) {
					filenameSplit.splice(0, 1);
					await createFolder(newZipFolder);
				} else {
					await writeThisFile(newZipFolder, filenameSplit.at(-1));
				}
			};

			await createFolder();
		} else {
			await writeThisFile();
		}
	}
	globals.state.isLoadingScreenShown = false;
	if (hasFailed) {
		await showDialog(t("someFilesFailedToDownload"));
	}
	const blob = zip.generate({
		type: "blob",
	});
	const dataUrl = URL.createObjectURL(blob);
	downloadDataUrl(dataUrl, domain + ".zip");
}

export function getDomain(domain?: string, defaultDomain = ""): string {
	if (!domain) {
		return defaultDomain;
	}
	domain = domain.toLowerCase();
	const { SUFFIXES } = globals;
	for (const key in SUFFIXES) {
		const suffix = SUFFIXES[key as keyof typeof SUFFIXES];
		if (domain.endsWith(suffix)) {
			domain = domain.substring(0, domain.length - suffix.length);
			break;
		}
	}
	if (defaultDomain) {
		if (!isNaN(Number(domain))) {
			domain = "a" + domain;
		}
		domain =
			domain
				.replaceAll("_", "-")
				.replace(
					/[^-.\da-záéíñóú\u4e00-\u9fa5]|^https?|\.?www\.|\.cname\.rthe\.cn|\s|^(\.|-)+|(\.|-)+$|\.-+|-+\./g,
					"",
				)
				.replace(/\.+/g, ".") || defaultDomain;
	}
	return domain;
}

export function getFullDomain(domain: string): string {
	return domain + getSuffix(domain);
}

export function getSuffix(domain = ""): string {
	let suffix = "";
	if (!domain || !domain.includes(".")) {
		if (
			!globals.privilege &&
			!globals.browserCheck.isBadDns &&
			isMainland()
		) {
			suffix = globals.SUFFIXES.FREE;
		} else {
			suffix = globals.SUFFIXES.PREMIUM;
		}
	}
	return suffix;
}

export async function importSite(
	files: SiteContent,
	fullDomain = globals.state.currentSite,
): Promise<void> {
	if (!globals.privilege) {
		void showDialog(t("premiumRequiredForImportingSites"));
		return;
	} else if (!files["settings.rth"]) {
		void showDialog(t("unableImportThisFile"));
		return;
	}
	await showDialog(t("originalDataWillBeErased"), {
		showCancel: true,
	});
	Object.keys(files).forEach((key) => {
		if (typeof files[key] === "object" && key !== "settings.rth") {
			delete files[key];
		}
	});
	try {
		await api.importSite(files, getDomain(fullDomain));
		void openSite(fullDomain);
	} catch (error) {
		void api.handleApiError(error);
	}
}

export function isSiteOpened(): boolean {
	if (globals.state.currentSite) {
		return true;
	} else {
		void showDialog(t("notOpenedWebsite"));
		return false;
	}
}

export async function loadSites(): Promise<void> {
	try {
		if (!globals.login.username) {
			return;
		}
		globals.state.sites = [];
		globals.state.isLoadingScreenShown = true;
		globals.state.sites = await api.getSiteList();
	} catch (error) {
		void api.handleApiError(error);
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

export function newSite(oldDomain = ""): void {
	if (globals.newSiteOverride) {
		globals.newSiteOverride();
		return;
	} else if (!globals.login.username) {
		void showDialog(t("notLoggedIn"));
		return;
	}

	globals.state.oldDomain = oldDomain;
	globals.state.isAddSitePopupShown = true;
}

export async function openSite(fullDomain: string): Promise<void> {
	if (globals.newSiteOverride) {
		globals.newSiteOverride();
		return;
	}

	await showAgreement();
	globals.state.currentSite = fullDomain;
	setValue();
	globals.currentHostInitial = {} as SiteContent;
	resetFileList();
	globals.state.currentColumn = Column.FileList;
	globals.state.isLoadingScreenShown = true;
	reloadMonacoEditor();

	const domain = getDomain(fullDomain);
	try {
		const data = await api.getSiteContent(domain);
		globals.state.isLoadingScreenShown = false;
		const config = data["settings.rth"];
		if (!configExists(config)) {
			return;
		}
		if (config.alert) {
			globals.state.currentSite = "";
			void showDialog(config.alert);
			return;
		}
		const filenames = Object.keys(data);
		for (let i = 0; i < filenames.length; i++) {
			const name = filenames[i];
			if (!name.includes("/")) {
				continue;
			}
			const folderName = getFolderName(name);
			if (!filenames.includes(folderName)) {
				data[folderName] = "";
			}
		}
		globals.state.files = data;
		globals.currentHostInitial = JSON.parse(
			JSON.stringify(globals.state.files),
		) as SiteContent;
		globals.state.currentFile = config.home;
		globals.state.currentFolder = getFolderName(config.home);
		await openFile(config.home);
	} catch (error) {
		globals.state.isLoadingScreenShown = false;
		void api.handleApiError(error);
	}
	void testConnection(domain);
}

export function rmBackup(): void {
	localStorage.removeItem("CurrentHost");
	localStorage.removeItem("CurrentHostname");
}

export function saveHostSettings(): void {
	void save({
		callback: PresetSaveCallback.SHOW_TOAST,
		content: globals.state.files["settings.rth"],
		key: "settings.rth",
	});
}

export async function showAgreement(): Promise<void> {
	if (!localStorage.getItem("Agree")) {
		await showDialog(
			t("agreement", {
				privacyPolicy:
					'<a href="https://docs.retiehe.com/privacy.html" target="_blank">' +
					t("privacyPolicy") +
					"</a>",
				termsOfService:
					'<a href="https://docs.retiehe.com/tos.html" target="_blank">' +
					t("termsOfService") +
					"</a>",
			}),
			{
				isHtml: true,
				showCancel: true,
			},
		);
		localStorage.setItem("Agree", Date.now().toString());
	}
}

export function testConnection(host: string): Promise<boolean> {
	return new Promise<boolean>((resolve) => {
		if (!isMainland()) {
			resolve(true);
			return;
		}
		const xhr = new XMLHttpRequest();
		const handleError = (): void => {
			void showDialog(t("foreignWebsitsBlocked"));
		};
		xhr.timeout = 5000;
		xhr.onload = (): void => {
			const isSuccessful = xhr.status === 204;
			if (!isSuccessful) {
				handleError();
			}
			resolve(isSuccessful);
		};
		xhr.onerror = handleError;
		xhr.ontimeout = handleError;
		xhr.open(
			"GET",
			"https://" +
				host.replaceAll(".", "-") +
				getSuffix() +
				"/connect.rth",
		);
		xhr.send();
	});
}
