<script setup lang="ts">
import SSL from "@/SSL";
import globals from "@/globals";
import { showDialog, sleep } from "@/utils";
import { t } from "i18next";
import { ref, useId, useTemplateRef } from "vue";

const SIZE_LIMIT = 1048576;

const certificateId = useId();
const certificateRef = useTemplateRef<HTMLInputElement>("certificate");
const isClosing = ref<boolean>(false);
const privateKeyId = useId();
const privateKeyRef = useTemplateRef<HTMLInputElement>("privateKey");

const closePopup = async (): Promise<void> => {
	isClosing.value = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isSslPopupShown = false;
	isClosing.value = false;
};

const uploadSsl = (): void => {
	const certInput = certificateRef.value;
	const keyInput = privateKeyRef.value;
	if (!certInput?.files || !keyInput?.files) {
		return;
	}
	const cert = certInput.files[0];
	const key = keyInput.files[0];
	const ssl = new SSL();
	void closePopup();
	if (!cert || !key) {
		void ssl.deleteSsl();
		return;
	}
	if (cert.size > SIZE_LIMIT || key.size > SIZE_LIMIT) {
		void showDialog(t("certificateInvalid"));
		return;
	}
	void ssl.uploadSsl(cert, key);
};
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closePopup"
	>
		<div
			class="popup"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
			role="dialog"
		>
			<h1>{{ $t("uploadSslCertificate") }}</h1>
			<label :for="certificateId">
				{{ $t("certificate") }}
				<a
					href="https://cloud.tencent.com/product/ssl"
					target="_blank"
					rel="noopener"
					>{{ $t("applyForFree") }}</a
				>
			</label>
			<div class="description">
				{{ $t("certificateTip") }}
			</div>
			<input
				:id="certificateId"
				ref="certificate"
				class="input-box"
				type="file"
			/>
			<label :for="privateKeyId">{{ $t("privateKey") }}</label>
			<div class="description">
				{{ $t("privateKeyTip") }}
			</div>
			<input
				:id="privateKeyId"
				ref="privateKey"
				class="input-box"
				type="file"
			/>
			<div class="btn-bar">
				<button
					class="cancel-btn"
					type="button"
					@click="closePopup"
				>
					{{ $t("cancel") }}
				</button>
				<button
					class="default-btn"
					type="button"
					@click="uploadSsl()"
				>
					{{ $t("upload") }}
				</button>
			</div>
		</div>
	</focus-trap>
</template>
