<template>
	<div class="overlay fade-in">
		<div
			class="loader"
			data-testid="loading"
		></div>
	</div>
</template>

<style scoped>
.loader {
	--size: 50px;
	animation: spin 1s infinite;
	border: 5px solid var(--bg-color);
	border-radius: 50%;
	border-top-color: var(--text-theme-color);
	bottom: 0;
	height: var(--size);
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: var(--size);
}

.overlay {
	backdrop-filter: blur(5px);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
