<template>
	<div class="ai-icon">AI</div>
</template>

<style scoped>
.ai-icon {
	--size: 14px;
	align-items: center;
	background-color: var(--fg-color);
	border-radius: 3px;
	color: var(--bg-color);
	display: flex;
	font-size: 12px;
	height: var(--size);
	line-height: 1;
	justify-content: center;
	text-align: center;
	width: var(--size);
}
</style>
