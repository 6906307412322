import { isRenamable, prepareUpload, renameFiles } from "@/file";
import globals from "@/globals";
import { isSiteOpened } from "@/site";
import { showDialog } from "@/utils";
import { t } from "i18next";

export async function handleDrop(event: DragEvent): Promise<void> {
	if (!event.dataTransfer || !isSiteOpened()) {
		return;
	}
	if (event.dataTransfer.items.length > 0) {
		const files: File[] = [];
		const names: string[] = [];
		if (
			typeof event.dataTransfer.items[0].webkitGetAsEntry === "function"
		) {
			const items: (DataTransferItem | FileSystemEntry)[] = [];
			for (let i = 0; i < event.dataTransfer.items.length; i++) {
				items.push(event.dataTransfer.items[i]);
			}
			readEntry(files, names, items);
		} else {
			void showDialog(t("notSupported"));
		}
	} else if (isRenamable(globals.dragged)) {
		if (!event.target) {
			return;
		}
		const target = event.target as HTMLElement;
		if (!target.classList.contains("folder")) {
			return;
		}
		const oldNameSplit = globals.dragged.split("/");
		const folderName = target.dataset.path;
		const newName =
			folderName + (oldNameSplit.at(-1) || oldNameSplit.at(-2) || "");
		if (folderName !== globals.dragged && newName !== globals.dragged) {
			await showDialog(t("confirmMoveThisFile"), {
				showCancel: true,
			});
			renameFiles(new Map([[globals.dragged, newName]]));
		}
	}
}

function readEntry(
	files: File[] = [],
	names: string[] = [],
	items: (DataTransferItem | FileSystemEntry)[] = [],
): void {
	const item = items[0];
	if (!item) {
		return;
	}
	let entry: FileSystemEntry | null;
	if (
		item instanceof DataTransferItem &&
		typeof item.webkitGetAsEntry === "function"
	) {
		entry = item.webkitGetAsEntry();
	} else {
		entry = item as FileSystemEntry;
	}
	items.splice(0, 1);
	if (!entry) {
		console.warn("No entry; skipping.");
		readEntry(files, names, items);
		return;
	}
	if (entry.isDirectory) {
		const dirEntry = entry as FileSystemDirectoryEntry;
		if (dirEntry.name.endsWith("_files")) {
			void showDialog(t("stealCodeProhibited"));
			return;
		}
		if (dirEntry.name === "node_modules") {
			readEntry(files, names, items);
			return;
		}
		dirEntry.createReader().readEntries((entries) => {
			items = items.concat(entries);
			readEntry(files, names, items);
		}, console.error);
	} else {
		const fileEntry = entry as FileSystemFileEntry;
		fileEntry.file((file) => {
			if (file.size > 0 && !fileEntry.fullPath.includes("/.")) {
				const path = fileEntry.fullPath.split("/");
				path.splice(0, path.length > 2 ? 2 : 1);
				files.push(file);
				names.push(path.join("/"));
			}
			if (items.length === 0) {
				void prepareUpload(files, names);
			}
		}, console.error);
		if (items.length > 0) {
			readEntry(files, names, items);
		}
	}
}
